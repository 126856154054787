import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  validLangs = ['en', 'de', 'fr', 'es', 'pt'];
  private translationFiles = [
    'en.json',
    // 'es.json',
    'fr.json',
    // add other translation files hererequest_new_booster
  ];

  private translations: any = {
    "en": {
      "auto_swap_set_order_tooltip": "Activates when the booster is offline for 90+ minutes. Does not apply to Favorites!",

      "no_recent_games": "No recent plays.",

      "your_favorites": "Your Favorites",
      "recently_played_with": "Recently Played With",
      "games": "games",
      "added_on": "added on",

      "favorite_booster_add_tooltip": "Add to favorites",
      "favorite_booster_remove_tooltip": "Remove from favorites",
      "favorite_booster_added": "Favorite Booster Added",
      "favorite_booster_removed": "Favorite Booster Removed",
      "favorite_booster_error_customer_not_found": "Customer not found",
      "favorite_booster_error_already_exists": "Booster already exists in your favorites list",
      "favorite_booster_error_booster_not_found": "Booster not found",
      "error_server_or_network": "Server or Network Error",
      "error_unknown": "Unknown Error",

      "starting": "Starting",
      "current": "Current",
      "desired": "Desired",

      "starting_points": "Starting Points",
      "starting_lp": "Starting LP",
      "starting_rp": "Starting RP",
      "starting_rr": "Starting RR",
      "starting_vp": "Starting VP",

      "champ_selection_info": "Selecting 4+ champions is free. A {{REPLACE}}% fee is added for choosing 1 to 3.",
      "current_selection": "Current Selection",
      "youll_earn": "You'll earn",
      "crypto_payment_info": "This payment may take a few minutes to be processed.",
      "subtotal": "Subtotal",
      "complete_purchase": "Complete Purchase",
      "global_discount": "Global Discount",
      "platform_fee_tooltip": "Funds security updates and continuous site improvements.",
      "platform_fee": "Platform Fee",
      "transaction_fee_tooltip": "Fee that covers secure payment processing.",
      "transaction_fee": "Transaction Fee",
      "campaign_discount": "Campaign Discount",

      "unranked_wins": "Desired number of unranked wins.",

      "select_character_lol" : "Select your Champion",
      "select_characters_lol" : "Select your Champions",
      "select_character_wild-rift" : "Select your Champion",
      "select_characters_wild-rift" : "Select your Champions",
      "select_character_valorant" : "Select your Agent",
      "select_characters_valorant" : "Select your Agents",
      "select_character_legends" : "Select your Legend",
      "select_characters_legends" : "Select your Legends",
      "select_character_marvel": "Select your Hero",
      "select_characters_marvel": "Select your Heroes",

      "quick_wins_marvel": "Desired number of quick wins.",
      "character_label_lol": "Champions",
      "character_label_wild-rift": "Champions",
      "character_label_valorant": "Agents",
      "character_label_apex": "Legends",
      "character_label_marvel": "Heroes",

      "game_selector_title_marvel": "Marvel Rivals boost services",
      "your_current_points": "Your Current Points",
      "current_points": "Current Points",
      "select_current_points": "Select Current Points",

      "last_known_rank": "Last Known Rank",
      "last_known_rank_tooltip": "If you were previously ranked but not in the most recent season, select your last recorded rank.",

      "no_posts": "No posts yet!",
      "streaming_tooltip": "Watch your Booster play live on Youtube/Twitch in a Private Stream",
      "voice_tooltip": "Open voice communication with the Booster while playing",

      // MERGE MISSING
      "&_summoner_spells": "& Summoner Spells",
      "choose_champions": "Choose your Champions",
      "choose_champions_tooltip": "Specify which Champions you wish to use while playing with your booster.",
      "choose_legends": "Choose your Legends",
      "choose_legends_tooltip": "Specify which Legends you wish to use while playing with your booster.",
      "choose_legends_tooltip_solo": "Specify which Legends you wish the booster to use, if they are not instantly locked.",
      "solo_only": "Solo Only",
      "solo_only_tooltip": "Guarantee your booster won't play with anyone else.",
      "streaming_voice": "Streaming + Voice",

      "register_account": "Register Acount",
      "recover_password": "Recover password",

      "mastery_boosting_lol": "Guaranteed way to reach your desired Mastery Level on a particular champion.",
      "leveling_boosting": "Guaranteed way to reach your desired account level through unranked games.",
      "normal_wins_boosting_lol": "Desired number of unranked wins.",
      "unrated_wins_valorant": "Desired number of unrated wins.",
      "games_boosting": "Desired number of ranked games with guaranteed booster performance.",
      "net_wins_boosting": "Guaranteed positive number of ranked wins. For each loss, you get an extra win.",
      "mastery_champ_lol": "Your selected champion for mastery boosting",
      "map_cs": "Your selected map for competitive boosting.",

      "your_current_lp_amount": "Your current LP.",
      "your_current_lp_amount_eq": "Your current LP is equal or above",
      "your_current_rr_amount": "Your current RR.",
      "your_current_rr_amount_eq": "Your current RR is equal or above",
      "your_current_rp_amount": "Your current RP.",
      "your_current_marks": "Your current Marks.",

      "tip_tooltip": "You are giving a tip to your booster. Tips are not mandatory but deeply appreciated. Thank you!",
      "express_tooltip": "Top priority pick and quicker completion, 24/7.",
      "cashback_tooltip": "of cashback to use on your next purchases!",
      "character_tooltip": "Select your prefered",
      // "streaming_tooltip": "Spectate matches through our custom live experience.",

      "valorant_character_tooltip": "You have selected your prefered Agents.",
      "apex_character_tooltip": "You have selected your prefered Legends.",
      "lol_character_tooltip": "You have selected your prefered Champions.",
      "marvel_character_tooltip": "You have selected your prefered Heroes.",

      "additions": "Additions",

      "playstyle_info_duos": "Let the booster know your personal preferences.",

      "choose_roles_tooltip_solo": "Specify which Roles you wish the booster to use while playing on your account.",
      "choose_champions_tooltip_solo": "Specify which Champions you wish the booster to use, if they are not picked or banned.",
      "choose_agents_tooltip_solo": "Specify which Agents you wish the booster to use, if they are not instantly locked.",


      // NEW MISSING
      "select_role_champions": "Select Your Roles & Champions",
      "select_summoner_spell_keyset": "Select Summoner Spell Keyset",
      "confirm": "Confirm",

      "select_your_agents": "Select your Agents",

      "request_new_booster_desc": "Let us know why do you want a new booster. We will assign you a new one shortly.",

      "request_reason": "Request reason",

      "assign_favorite_booster": "Assign Favorite Booster",
      "assign_favorite_booster_desc": "The boosters you gave positive reviews on previous orders are added to your favorite's list.",
      "assign_booster": "Assign Booster",
      "booster_not_in_game": "Make sure your booster is not in-game!",

      "request_booster_attention": "Request Booster Attention",
      "request_booster_attention_desc": "Notify the currently assigned booster that you are ready to resume the order.",
      "you_may_perform": "You may perform this action up to",
      "per_day": "times per day",

      "cancel": "Cancel",

      "tipping_options": "Tipping Options",
      "tipping_options_no_boosters": "Looks like you don't have any booster history for this order!",

      "use_credits": "Use Credits",
      "custom_tip": "Custom Tip",

      "auto_swap_desc": "Enable 'Auto Swap', and we'll automatically switch you to another booster, if your current one goes offline for",
      "auto_swap_remember": "Remember, this feature does not apply for boosters you personally picked from your favorites list.",
      "auto_swap_current_status": "Current status:",
      "auto_swap_booster_swap": "Booster will be swapped in",
      "auto_swap_information_updated": "Information updated every 5 minutes.",
      "auto_swap_enable": "Enable 'Auto Swap'",
      "auto_swap_disable": "Disable 'Auto Swap'",

      "pages": "Pages",
      "contact": "Contact",

      "review_your_booster": "Review Your Booster",
      "review_your_boosters": "Review Your Boosters",
      "review_usage": "Use the star rating selector above to review your booster",
      "review_please_write": "Please write your review",
      "review_no_booster": "Looks like you didn't have any booster in this order!",

      "looks_like_no_order":"Looks like you don't have any orders here!",
      "press_purchase_boost":"Press \"Purchase Boost\" on your right to get your desired rank now!",

      //-------------------------------------------

      "report_translation": "Report Translation",
      "open": "Open",
      "closed": "Closed",

      "ticket_add_answer": "Add Answer",
      "ticket_details": "Ticket Details",
      "ticket_close": "Close Ticket",
      "sent_at": "Sent at",
      "customer": "Customer",

      "legend": "Legend",
      "badge" : "Badge",

      "country_selection": "Select Country",

      "no_boosters_reviewed": "Looks like you haven't given any reviews to boosters yet!",

      "no_customizations": "Looks like you don't have any customizations for this order!",

      // HIDDEN
      "en_flag": "US flag image",
      "de_flag": "German flag image",
      "fr_flag": "French flag image",
      "es_flag": "Spanish flag image",
      "pt_flag": "Portugal flag image",

      // NEW
      "use_your_spendables": "Use your spendables",
      "store_credits": "Use Store Credits",

      "select_current_marks": "Select your current marks",

      "select_badge": "Select Badge",
      "popular_badges": "Popular Bagdes",
      "all_badges": "All Badges",

      "select_character_apex": "Select your Legend",
      "select_characters_apex": "Select your Legends",

      "select": "Select",

      "tip_booster": "Tip Booster",

      "login":"Login",
      "username": "Username",
      "password": "Password",

      // attention texts
      "new_episode": "New Episode",
      "new_split": "New Split",
      "new_act": "New Act",
      "new_set": "New Set",

      // my-account
      "purchase_boost": "Purchase Boost",
      "create_ticket": "Create Ticket",
      "active_orders": "Active Orders",
      "order_history": "Order History",
      "support": "Support",
      "spendables": "Spendables",
      "edit_account": "My Account",
      "logout": "Logout",

      "subject": "Subject",
      "status": "Status",
      "created_at": "Created At",

      "service": "Service",
      "purchase_date": "Purchase Date",
      "details": "Details",

      // new support ticket
      "new_support_ticket": "New Support Ticket",
      "category": "Category",
      "select_category": "Select a category",
      "bug_report": "Report Bug",
      "general_question": "General Question",
      "billing_financial": "Billing",
      "other": "Other",
      "problem_description": "Description",
      "submit_ticket": "Submit Ticket",

      // spendables
      "amount": "Amount",
      "currency": "Currency",
      "available_discounts": "Available Discounts",

      "home_page_title_page": "GGBoost Blog - Top Gaming and Boosting News - Page",
      "home_page_title": "Best-Selling League of Legends Boosting & Valorant Elo Boost",
      "home_page_desc": "GGBoost is the leading provider of Elo Boosting services for League of Legends and Valorant. Boost your rank and improve your gaming experience today!",

      "blog_page_title": "GGBoost Blog - Top Gaming and Boosting News",
      "blog_page_title_page": "GGBoost Blog - Top Gaming and Boosting News - Page",
      "blog_page_desc": "Stay updated with GGBoost.com\'s blog for the latest in gaming. Discover articles, insights, and trends tailored for gaming enthusiasts.",

      "boosting_services": "Boosting Services",
      "boosting_services_page_title": "Boosting Services - GGBOOST",

      "blog": "Blog",
      "reviews": "Reviews",
      "tutorial": "Tutorial",
      "my_account": "My Account",
      "our_games": "Our Games",
      "best_boost": "The Best Elo Boosting Experience",
      "get_your": "Get your",
      "desired_rank": "Desired Rank",
      "now!": "now!",
      "check_tutorial": "Check Tutorial",
      "buy_boost_now": "Buy Boost Now",

      "game_selector_title_lol": "Elo boost services",
      "game_selector_title_valorant": "Valorant boost services",
      "game_selector_title_tft": "TFT boost services",
      "game_selector_title_wild_rift": "Wild Rift boost services",
      "game_selector_title_cs2": "CS2 boost services",
      "game_selector_title_apex": "APEX boost services",

      "best_selling": "Best-Selling League of Legends & Valorant Boosting Services",
      "best_selling_desc": "We believe that every customer deserves a special, tailored experience.",
      "the_safest": "The Safest",
      "the_safest_desc": "Our commitment to our customers is to always maintain a high level of service and safety.",
      "trustworthy": "Trustworthy",
      "trustworthy_desc": "With an astounding 5 star rating on Trustpilot, we are pleased to be the top-rated boosting website in the industry.",
      "top_results": "Top Results",
      "top_results_desc": "We top the boosting industry with an overall 87% win ratio across all services.",

      "unique": "What Makes GGBoost Unique?",
      "ingame_chat_tracking": "In-Game And Lobby Chat Tracking",
      "ingame_chat_tracking_desc": "We are the only company in the market that has the ability to monitor everything the booster does in your account to ensure that it is always secure.",
      "login_secure": "Your Login Is Secure At All Times",
      "login_secure_desc": "Your private login information is never accessible to boosters, instead, our unique program logs them in automatically.",
      "advanced_vpn": "Advanced VPN",
      "advanced_vpn_desc": "Our Advanced VPN automatically connects your booster to a region close to you, making it appear as though you are playing from home.",

      "your_boost_your_rules": "Your Boost, Your Rules",
      "fully_customizable": "Fully Customizable",
      "fully_customizable_desc": "Select your preferred Champions/Agents, Roles and your usual Summoner Spell order, completely free of charge. Speed up the order completion with our Express Plus option.",
      "favorite_boosters": "Favorite Boosters",
      "favorite_boosters_desc": "Click the heart icon in your order dashboard to add a booster to your favorites. In future orders you will be able to see when they are available and easily assign them.",
      "live_chat_schedule": "Live Chat and Schedule",
      "live_chat_schedule_desc": "Use the Order Chat to communicate with your booster in real-time, and Schedule your Duo gaming sessions.",

      "read_more": "Read More",
      "previous_page": "Previous Page",
      "next_page": "Next Page",
      "read_more_about": "Read more about",

      "ready_to_start": "Ready to Start? Buy Now!",

      "what_our_customers_say": "What our Customers Say",

      "live_support": "Live Support",
      "got_any_questions": "Got Any Questions?",
      "got_any_questions_desc": "We have a dedicated team of customer service representatives available 24/7 to help you with any questions.",
      "chat_with_us": "Chat With Us",

      "about_our_company": "About Our Company",

      // service layout
      "last_act_rank": "Last Act Rank",
      "last_season_rank": "Last Season Rank",
      "current_rank": "Current Rank",
      "current_rating": "Current Rating",
      "current_mastery": "Current Mastery",
      "current_level": "Current Level",
      "desired_badge": "Desired Badge",
      "desired_amount": "Desired Amount",
      "desired_rating": "Desired Rating",
      "desired_mastery": "Desired Mastery",
      "desired_level": "Desired Level",
      "desired_legend": "Desired Legend",

      // plays of the week
      "view_more_on": "View More on",

      // order-flow:
      "getting_your_order": "Getting Your Order",
      "start_ranking": "Start Ranking up in 3 simple steps",
      "customize_your_service": "Customize Your Service",
      "complete_your_payment": "Complete your payment",
      "select_your_desired_payment_method": "Select your desired payment method. We support Google Pay, Apple Pay, Visa, Stripe and PaySafeCard, among others.",
      "start_ranking_up": "Start ranking up",
      "order_ready_to_go": "Now that your order is ready to go, a booster will be assigned to you right away. It's time to climb the ranks!",

      // reviews
      "gg_boost_providing": "GGBoost: Providing Excellence for",
      "years": "Years",
      "with_over": "With over",
      "customer_reviews": "Customer Reviews",
      "show_more": "Show More",

      // buy-authenticate
      "welcome_back": "Welcome Back",
      "please_login_or_register": "Please login or register an account to continue!",
      "almost_there": "You're almost there!",
      "please_login_or_register_buy": "Please Register or Login before buying your order!",
      "continue_with": "Continue With",
      "register_with": "Register With",
      "or": "or",
      "forgot_password": "Forgot password?",
      "have_an_account": "Have an account? Login!",
      "duo_boosting_text": "Play on your account together with one of our boosters.",
      "badges_boosting_text": "You will unlock the Badge",
      "for": "for",

      "solo_boosting_text": "One of our boosters will play on your account.",
      "division_boosting_text": "Guarantee your desired rank.",
      "premier_queue": "You've selected the Premier Ranking Queue for your boost.",
      "competitive_queue": "You've selected the Competitive Ranking Queue for your boost.",
      "rating_boost": "Guarantee your desired CS Rating.",
      "placement_boosting_lol": "Desired number of placement games with a guaranteed minimum 70% win ratio.",
      "placement_boosting_valorant": "Desired number of placement games with a guaranteed minimum 80% win ratio.",
      "placement_boosting_tft": "Desired number of placement games with a guaranteed average of 3rd place or higher.",
      "placement_boosting_apex": "Desired number of provisional games with a guaranteed average of Top 2 place or higher.",
      "placement_boosting_cs2_desired": "Desired Number of",
      "placement_boosting_cs2_queue_premier": "Premier",
      "placement_boosting_cs2_queue_competitive": "Competitive",
      "placement_boosting_cs2_wins": "Placement Wins.",

      "free": "Free",

      "duo": "Duo",
      "duo_desc": "You team up with a booster",
      "solo": "Solo",
      "solo_desc": "Booster plays on your account",
      "not_available": "Not Available",

      "choose_roles": "Choose your Roles",
      "and_champs": "& Champions",
      "roles_tooltip": "Specify which Roles you wish to use while playing with your booster.",

      "choose_agents": "Choose your Agents",
      "agents_tooltip": "Specify which Agents you wish to use while playing with your booster.",

      "choose_heroes": "Choose your Heroes",
      "choose_heroes_tooltip": "Specify which Heroes you wish to use while playing with your booster.",
      "choose_heroes_tooltip_solo": "Specify which Heroes you wish the booster to use, if they are not instantly locked.",

      "offline_vpn": "Offline Mode + Advanced VPN",
      "offline_tooltip": "Appear offline while playing + VPN with automatic location detection",

      "coaching": "Elements of Coaching",
      "coaching_tooltip": "Ask for advice on how to improve your performance in a ranked game environment.",

      "express_priority": "Express Priority",
      "express_priority_tooltip": "Upgrade to Express for higher priority pick and completion speed.",

      "use_your_credits": "Use your credits",
      "use_your_discounts": "Use your discounts",

      "use_global_discount": "Use Global Discount",
      "service_discount": "Use Service Discount",

      "total_price": "Total Price",

      "excellent": "Excellent",

      "available_credits": "Available Credits",
      "order_price": "Order Price",
      "credits_used": "Credits Used",
      "order_details": "Order Details",
      "use_gg_credits": "Use GGBoost Credits",

      "select_your_order_details": "Select your order details",

      // flag
      "ready_5_min": "Ready in 5 minutes",
      "avg_comp_time": "Average Completion Time",

      "hour": "hour",
      "hours": "hours",
      "day": "day",
      "days": "days",

      "comp_queue": "Competitive Queue",
      "queue": "Queue",
      "queue_cs": "Mode",

      "server": "Server",
      "platform": "Platform",
      "region": "Region",
      "map": "Map",

      "estimated_time_left": "Estimated time left",

      "select_rank": "Select Rank",
      "select_rank_desired": "Select  Desired Rank",
      "select_queue": "Select Queue",
      "select_region": "Select Your Region",

      "select_current_lp": "Select your current LP",
      "select_current_rr": "Select your current RR",

      "select_last_season_rank": "Select Last Season Rank",

      "current_lp": "Current LP",
      "current_rp": "Current RP",
      "current_rr": "Current RR",
      "current_vp": "Current VP",

      "marks": "Marks",

      // trustpilot reviews component
      "trustpilot_reviews_title": "Thousands of Verified Reviews",
      "trustpilot_reviews_subtitle": "See Why Over 150.000 Players Trust Us",

      // apex page
      "coming_soon": "coming soon",

      // payment-success
      "processing_payment": "Processing Payment",

      // customer order page
      "pause_order": "Pause Order",
      "unpause_order": "Unpause Order",
      "spectate_match": "Spectate Match",
      "review_order": "Review Order",

      // MISSING
      "select_desired_map": "Select Your Desired Map",

      "safe_service": "Safe Service",
      "fast_completion": "Fast Completion",
      "money_back": "Money Back Guarantee",
      "support_247": "24/7 Support",

      "select_current_rp": "Select your current RP",
      "select_platform": "Select Platform",

      "account_information": "Account Information",
      "additional_info": "We need some additional information before we can start your order",
      "2fa_notification": "Please make sure 2-Factor Authentication is DISABLED on your Account, otherwise, the booster will not be able to Login.",

      "application": "Application",
      "your_profile_id": "Your Profile ID",
      "assign_favorite_booster_label": "Assign Favorite Booster",
      "no_favorite_boosters": "You don't have favorite boosters yet.",
      "first_booster": "First Booster Available",
      "online_boosters": "Currently online boosters",
      "offline_boosters": "Currently offline boosters",
      "last_rating": "Last rating",
      "order_notes": "Order Notes",
      "order_notes_placeholder": "Fill with any relevant detail",
      "customize_order": "Customize your Order",
      "playstyle_info": "Select your personal preferences that the booster must follow",
      "please_fill": "Please fill all fields marked with *",
      "by_clicking": "By clicking 'Save & Start Order', you agree to the",
      "and": "and",

      "save_start": "Save & Start Order",
      "privacy_policy": "Privacy Policy",
      "terms_use": "Terms of Use",

      "main_role": "main role",
      "select_main_role": "Select Main Role",
      "secondary_role": "secondary role",
      "select_secondary_role": "Select Secondary Role",
      "add": "add",

      "select_your_champions": "Select Your Champions",
      "search": "Search",
      "confirm_selection": "Confirm Selection",

      "error": "Error",
      "information_error_msg": "We either can't find your Riot ID or an error occured during the lookup process! If this keeps happening please contact our customer support!",

      "information_mismatch": "Information Mismatch",
      "information_mismatch_msg": "Your rank does not fit your purchased order! Please confirm your Riot ID!",
      "order_starting_rank": "Order Starting Rank",
      "edit_riot_id": "Edit Riot ID",

      "verify_your_summoner": "Verify your Riot ID",
      "level": "Level",
      "confirm_summoner": "Confirm Riot ID",

      "order_converted": "Your order was converted.",
      "order_chargeback": "Your order was chargebacked.",
      "order_refunded": "Your order was refunded.",
      "order_pending": "Your payment is on hold.",
      "order_rejected": "Your payment was rejected.",
      "order_active_progress": "Your order is in progress.",
      "order_active_action": "Your action is required!",
      "order_completed": "Your order is completed!",
      "order_paused": "Your order is paused.",

      "order_converted_desc": "Your order was successfully converted to credits!",
      "order_chargeback_desc": "Contact Customer Support for more information!",
      "order_refunded_desc": "We have issued a refund to you, it might take up to 24 hours!",
      "order_rejected_desc": "Your payment has been declined by your provider!",
      "order_pending_desc": "Your order will start as soon as your payment is processed!",
      "order_active_progress_desc": "You can talk to your booster in the order chat!",
      "order_active_action_desc": "Fill the fields bellow to start your order!",
      "order_completed_desc": "Thank you for choosing us! Please leave us a review!",
      "order_paused_desc": "You can unpause your order at any time!",

      "please_edit_credentials": "Please edit your credentials!",
      "please_edit_credentials_desc": "Dear customer, the Login information you provided is incorrect.",
      "account_name": "Account Name",
      "account_password": "Account Password",
      "hide": "Hide",
      "show": "Show",
      "save_continue": "Save & Continue Order",

      "thank_you": "Thank you for choosing GGBOOST!",
      "admin_buy_help_msg": "Who was the agent that helped you the most while purchasing your first order?",
      "admin_buy_help_none": "None, I purchased on my own",

      "match_history": "Match History",
      "loading": "loading",

      "waiting_for_booster": "Waiting for booster",
      "currently_online": "Currently online",
      "currently_away": "Currently away",
      "currently_offline": "Currently offline",
      "textbox_placeholder": "Type here and hit Enter",

      "auto_swap": "Auto Swap",
      "auto_swap_tooltip": "Automatic swap if the booster is offline.",
      "click_more": "Click to see more.",
      "alert_booster": "Alert Booster",
      "request_new_booster": "Request Booster",
      "request_new_booster_tooltip": "Order must be paused to be able to request a new booster!",

      "order_id": "Order ID",
      "order_type": "Order Type",
      "order_status": "Order Status",
    },
    "fr": {
      "auto_swap_set_order_tooltip": "S’active lorsque le booster est hors ligne pendant plus de 90 minutes. Ne s’applique pas aux favoris !",

      "no_recent_games": "Aucune partie récente.",

      "your_favorites": "Vos Favoris",
      "recently_played_with": "Récemment Joué Avec",
      "games": "jeux",
      "added_on": "ajouté le",

      "favorite_booster_add_tooltip": "Ajouter aux favoris",
      "favorite_booster_remove_tooltip": "Retirer des favoris",
      "favorite_booster_added": "Booster favori ajouté",
      "favorite_booster_removed": "Booster favori retiré",
      "favorite_booster_error_customer_not_found": "Client introuvable",
      "favorite_booster_error_already_exists": "Le booster est déjà dans votre liste de favoris",
      "favorite_booster_error_booster_not_found": "Booster introuvable",
      "error_server_or_network": "Erreur de serveur ou de réseau",
      "error_unknown": "Erreur inconnue",

      "starting": "Depart",
      "current": "Actuel",
      "desired": "Souhaite",

      "starting_points": "Points de Départ",
      "starting_lp": "LP de Départ",
      "starting_rp": "RP de Départ",
      "starting_rr": "RR de Départ",
      "starting_vp": "VP de Départ",

      "champ_selection_info": "La sélection de 4 champions ou plus est gratuite. Un supplément de {{REPLACE}}% est ajouté pour le choix de 1 à 3 champions.",
      "current_selection": "Sélection Actuelle",
      "youll_earn": "Vous gagnerez",
      "crypto_payment_info": "Ce paiement peut prendre quelques minutes à être traité.",
      "subtotal": "Sous-total",
      "complete_purchase": "Finaliser l'achat",
      "global_discount": "Réduction globale",
      "platform_fee_tooltip": "Finance la sécurité des fonds et les améliorations continues du site.",
      "platform_fee": "Frais de plateforme",
      "transaction_fee_tooltip": "Frais couvrant le traitement sécurisé des paiements.",
      "transaction_fee": "Frais de transaction",
      "campaign_discount": "Réduction de Dampagne",

      "unranked_wins": "Nombre souhaité de victoires non classées.",

      "select_character_lol" : "Sélectionnez votre Champion",
      "select_characters_lol" : "Selectionnez vos Champions",
      "select_character_wild-rift" : "Sélectionnez votre Champion",
      "select_characters_wild-rift" : "Selectionnez vos Champions",
      "select_character_valorant" : "Sélectionnez votre Agent",
      "select_characters_valorant" : "Selectionnez vos Agents",
      "select_character_legends" : "Sélectionnez votre Légende",
      "select_characters_legends" : "Selectionnez vos Légendes",
      "select_character_marvel": "Sélectionnez votre Héros",
      "select_characters_marvel": "Selectionnez vos Héros",

      "character_label_lol": "Champions",
      "character_label_wild-rift": "Champions",
      "character_label_valorant": "Agents",
      "character_label_apex": "Légendes",
      "character_label_marvel": "Héros",

      "game_selector_title_marvel": "Services de Boost Marvel Rivals",
      "your_current_points": "Vos Points Actuels",
      "current_points": "Points Actuels",
      "select_current_points": "Sélectionnez les Points Actuels",

      "last_known_rank": "Dernier Rang Connu",
      "last_known_rank_tooltip": "Si vous avez été classé auparavant mais pas lors de la saison la plus récente, sélectionnez votre dernier rang enregistré.",

      "no_posts": "Pas encore de publications!",
      "streaming_tooltip": "Regarde ton Booster jouer en direct sur Youtube/Twitch dans un stream privé",
      "voice_tooltip": "Ouvre une communication vocale avec le Booster pendant que tu joues",

      // MERGE MISSING
      "&_summoner_spells": "& Sorts d'invocateur",
      "choose_champions": "Choisis tes Champions",
      "choose_champions_tooltip": "Dis quels Champions tu veux utiliser en jouant avec ton booster.",
      "choose_legends": "Choisis tes Légendes",
      "choose_legends_tooltip": "Dis quels Légendes tu veux utiliser en jouant avec ton booster.",
      "choose_legends_tooltip_solo": "Dis quels Légendes tu souhaites que le booster joue, si ils ne sont pas verrouillés tout de suite.",
      "solo_only": "Solo seulement",
      "solo_only_tooltip": "Garantis que ton booster ne jouera avec personne d'autre.",
      "streaming_voice": "Streaming + Voix",

      "register_account": "Créer un compte",
      "recover_password": "Récupérer le mot de passe",

      "mastery_boosting_lol": "Le moyen sûr d'atteindre le niveau de maîtrise souhaité sur un champion en particulier.",
      "leveling_boosting": "Le moyen sûr d'atteindre le niveau de compte souhaité via des parties non classées.",
      "normal_wins_boosting_lol": "Nombre souhaité de victoires non classées.",
      "unrated_wins_valorant": "Nombre souhaité de victoires non classées.",
      "games_boosting": "Nombre souhaité de parties classées avec un booster performant garanti.",
      "net_wins_boosting": "Nombre garanti de victoires classées. Pour chaque défaite, une victoire en plus.",
      "mastery_champ_lol": "Ton champion sélectionné pour le boost de maîtrise.",
      "map_cs": "Ta carte sélectionnée pour le boost compétitif.",

      "your_current_lp_amount": "Ton nombre actuel de LP.",
      "your_current_lp_amount_eq": "Ton nombre actuel de LP est égal ou supérieur à",
      "your_current_rr_amount": "Ton nombre actuel de RR.",
      "your_current_rr_amount_eq": "Ton nombre actuel de RR est égal ou supérieur à",
      "your_current_rp_amount": "Ton nombre actuel de PR.",
      "your_current_marks": "Tes marques actuelles.",


      "tip_tooltip": "Tu laisses un pourboire à ton booster. Les pourboires ne sont pas obligatoires mais super appréciés. Merci !",
      "express_tooltip": "Prise en charge prioritaire et réalisation plus rapide, 24h/24 et 7j/7.",
      "cashback_tooltip": "de cashback à utiliser sur tes prochains achats !",
      "character_tooltip": "Sélectionne ton",
      // "streaming_tooltip": "Regarde les matchs en direct grâce à notre expérience personnalisée.",

      "valorant_character_tooltip": "Vous avez sélectionné vos Agents préférés.",
      "apex_character_tooltip": "Vous avez sélectionné vos Légendes préférées.",
      "lol_character_tooltip": "Vous avez sélectionné vos Champions préférés.",
      "marvel_character_tooltip": "Vous avez sélectionné vos Héros préférés.",

      "additions": "Options supplémentaires",

      "playstyle_info_duos": "Fais savoir au booster tes préférences personnelles.",

      "choose_roles_tooltip_solo": "Dis quels Roles que tu souhaites que le booster utilise en jouant sur ton compte.",
      "choose_champions_tooltip_solo": "Dis quels Champions tu souhaites que le booster joue, si ils ne sont pas pris ou bannis.",
      "choose_agents_tooltip_solo": "Dis quels Agents tu souhaites que le booster joue, si ils ne sont pas verrouillés tout de suite.",


      // NEW MISSING
      "select_role_champions": "Sélectionnez vos rôles et champions",
      "select_summoner_spell_keyset": "Sélectionnez vos sorts d'invocateur",
      "confirm": "Confirmer",

      "select_your_agents": "Sélectionnez vos agents",

      "request_new_booster_desc": "Faites-nous savoir pourquoi vous souhaitez un nouveau booster. Nous vous en attribuerons un nouveau sous peu.",

      "request_reason": "Raison de la demande",

      "assign_favorite_booster": "Attribuer le booster favori",
      "assign_favorite_booster_desc": "Les boosters auxquels vous avez donné des avis positifs sur vos commandes précédentes sont ajoutés à votre liste de favoris.",
      "assign_booster": "Attribuer un booster",
      "booster_not_in_game": "Assurez-vous que votre booster n'est pas en jeu !",

      "request_booster_attention": "Demander l'attention du booster",
      "request_booster_attention_desc": "Notifiez le booster actuellement attribué que vous êtes prêt à reprendre la commande.",
      "you_may_perform": "Vous pouvez effectuer cette action jusqu'à",
      "per_day": "fois par jour",

      "cancel": "Annuler",

      "tipping_options": "Options de pourboire",
      "tipping_options_no_boosters": "Vous n'avez aucun historique de booster pour cette commande !",

      "use_credits": "Utiliser des crédits",
      "custom_tip": "Personnalisé",

      "auto_swap_desc": "Activez 'Auto Swap', et nous vous basculerons automatiquement vers un autre booster si votre booster actuel se déconnecte pendant",
      "auto_swap_remember": "N'oubliez pas que cette fonctionnalité ne s'applique pas aux boosters que vous avez personnellement choisis dans votre liste de favoris.",
      "auto_swap_current_status": "Statut actuel :",
      "auto_swap_booster_swap": "Le booster sera remplacé dans",
      "auto_swap_information_updated": "Informations mises à jour toutes les 5 minutes.",
      "auto_swap_enable": "Activer 'Auto Swap'",
      "auto_swap_disable": "Désactiver 'Auto Swap'",

      "pages": "Pages",
      "contact": "Contact",

      "review_your_booster": "Évaluez votre booster",
      "review_your_boosters": "Évaluez vos boosters",
      "review_usage": "Utilisez le sélecteur d'étoiles ci-dessus pour évaluer votre booster",
      "review_please_write": "Veuillez écrire votre avis",
      "review_no_booster": "Vous n'ayez eu aucun booster pour cette commande!",

      "looks_like_no_order":"On dirait que tu n'as pas encore de commandes ici!",
      "press_purchase_boost":"Appuie sur \"Acheter un boost\" à droite pour obtenir ton rang désiré maintenant!",

      //-------------------------------------------

      "report_translation": "Signaler Une Traduction",
      "open": "Ouvert",
      "closed": "Fermé",

      "ticket_add_answer": "Ajouter Une Réponse",
      "ticket_details": "Détails Du Ticket",
      "ticket_close": "Fermer Le Ticket",
      "sent_at": "Envoyé à",
      "customer": "Client",

      "legend": "Legend",
      "badge" : "Badge",

      "country_selection": "Sélectionner le Pays",

      "no_boosters_reviewed": "Vous n'avez pas encore donné d'avis sur les boosters!",

      "no_customizations": "Vous n'ayez eu personnalisations pour cette commande!",

      // HIDDEN
      "en_flag": "Image du drapeau des États-Unis",
      "de_flag": "Image du drapeau allemand",
      "fr_flag": "Image du drapeau français",
      "es_flag": "Image du drapeau espagnol",
      "pt_flag": "Image du drapeau portugais",

      // NEW
      "use_your_spendables": "Utilise tes credits",
      "store_credits": "Utilise Crédits de magasin",

      "select_current_marks": "Sélectionnez vos Marques Actuelles",

      "select_badge": "Sélectionner un Badge",
      "popular_badges": "Badges Populaires",
      "all_badges": "Tous les Badges",

      "select_character_apex": "Sélectionnez votre Legend",
      "select_characters_apex": "Sélectionnez vos Legends",

      "select": "Sélectionner",

      "tip_booster": "Pourboire Booster",

      "login":"Login",
      "username": "Nom d'Utilisateur",
      "password": "Mot de passe",

      // attention texts,
      "new_episode": "Nouvel Épisode",
      "new_split": "Nouveau Split",
      "new_act": "Nouvel Acte",
      "new_set": "Nouvel Set",

      // my-account,
      "purchase_boost": "Acheter Boost",
      "create_ticket": "Créer Ticket",
      "active_orders": "Commandes Actives",
      "order_history": "Historique Commande",
      "support": "Support",
      "spendables": "Dépensables",
      "edit_account": "Mon Compte",
      "logout": "Déconnexion",

      "subject": "Sujet",
      "status": "Statut",
      "created_at": "Créé à",

      "service": "Service",
      "purchase_date": "Date d\'Achat",
      "details": "Détails",

      // new support ticket,
      "new_support_ticket": "Nouveau Ticket Support",
      "category": "Catégorie",
      "select_category": "Sélectionnez une Catégorie",
      "bug_report": "Signaler un Bug",
      "general_question": "Question Générale",
      "billing_financial": "Facturation",
      "other": "Autre",
      "problem_description": "Description",
      "submit_ticket": "Soumettre Ticket",

      // spendables,
      "amount": "Montant",
      "currency": "Devise",
      "available_discounts": "Réductions Disponibles",

      "home_page_title_page": "Blog GGBoost - Actualités Gaming et Boosting - Page",
      "home_page_title": "Boosting League of Legends et Boost Elo Valorant les plus vendus",
      "home_page_desc": "GGBoost est le premier fournisseur de services de boosting d\'Elo pour League of Legends et Valorant. Augmentez votre rang et améliorez votre expérience de jeu dès aujourd\'hui.!",

      "blog_page_title": "Blog GGBoost - Actualités Gaming et Boosting",
      "blog_page_title_page": "Blog GGBoost - Actualités Gaming et Boosting - Page",
      "blog_page_desc": "Restez informé avec le blog de GGBoost.com pour connaître les dernières nouveautés gaming. Découvrez des articles et les tendances adaptés aux passionnés de gaming.",

      "boosting_services": "Services de Boosting",
      "boosting_services_page_title": "Services de Boosting - GGBOOST",

      "blog": "Blog",
      "reviews": "Évaluations",
      "tutorial": "Tutoriel",
      "my_account": "Mon Compte",
      "our_games": "Nos Jeux",
      "best_boost": "La Meilleure Expérience d\'Elo Boosting",
      "get_your": "Obtenez votre",
      "desired_rank": "Rang Désiré",
      "now!": "maintenant!",
      "check_tutorial": "Voir le Tutoriel",
      "buy_boost_now": "Acheter Maintenant",

      "game_selector_title_lol": "Services de Boost d\'Elo",
      "game_selector_title_valorant": "Services de Boost Valorant",
      "game_selector_title_tft": "Services de Boost TFT",
      "game_selector_title_wild_rift": "Services de Boost Wild Rift",
      "game_selector_title_cs2": "Services de Boost CS2",
      "game_selector_title_apex": "Services de Boost APEX",

      "best_selling": "Services de boosting League of Legends et Valorant les plus vendus",
      "best_selling_desc": "Nous pensons que chaque client mérite une expérience sur mesure.",
      "the_safest": "Le plus sûr",
      "the_safest_desc": "Notre engagement envers nos clients est de toujours maintenir un niveau élevé de service et de sécurité.",
      "trustworthy": "Digne de Confiance",
      "trustworthy_desc": "Avec une incroyable note de 5 étoiles sur Trustpilot, nous sommes heureux d\'être le site web de boosting le mieux noté de l\'industrie.",
      "top_results": "Meilleurs Résultats",
      "top_results_desc": "Nous sommes en tête de l\'industrie du boosting avec un taux de réussite global de 87% sur l\'ensemble des services.",

      "unique": "Qu\'est-ce qui rend GGBoost unique?",
      "ingame_chat_tracking": "Suivi des discussions dans le jeu et dans le lobby",
      "ingame_chat_tracking_desc": "Nous sommes la seule entreprise sur le marché qui a la capacité de contrôler tout ce que le booster fait sur votre compte pour s\'assurer qu\'il reste toujours sécurisé.",
      "login_secure": "Vos identifiants sont sécurisés à tout moment",
      "login_secure_desc": "Vos identifiants privés ne sont jamais accessibles aux boosters, notre programme les connecte automatiquement..",
      "advanced_vpn": "VPN Avancé",
      "advanced_vpn_desc": "Notre VPN avancé connecte automatiquement votre booster à une région proche de vous, ce qui donne l\'impression que vous jouez depuis chez vous.",

      "your_boost_your_rules": "Votre Boost Vos Règles",
      "fully_customizable": "Entièrement personnalisable",
      "fully_customizable_desc": "Sélectionnez vos rôles de champions/agents préférés et votre ordre de sorts d\'invocateur habituel sans aucun frais. Accélérez le traitement de votre commande grâce à notre option Express Plus.",
      "favorite_boosters": "Boosters Favoris",
      "favorite_boosters_desc": "Cliquez sur l'icône en forme de cœur dans votre tableau de bord pour ajouter un booster à vos favoris. Pour les prochaines commandes, vous pourrez voir quand ils sont disponibles et les assigner facilement.",
      "live_chat_schedule": "Chat en direct et planning",
      "live_chat_schedule_desc": "Utilisez le Chat de commande pour communiquer avec votre booster en temps réel et planifier vos sessions de jeu en duo.",

      "read_more": "Lire Plus",
      "previous_page": "Page Précédente",
      "next_page": "Page Suivante",
      "read_more_about": "Lire plus à propos de",

      "ready_to_start": "Prêt à commencer? Achetez Maintenant!",

      "what_our_customers_say": "Ce que nos Clients Disent",

      "live_support": "Support en Direct",
      "got_any_questions": "Des questions?",
      "got_any_questions_desc": "Nous disposons d\'une équipe de service clientèle disponibles 24/7 pour répondre à toutes vos questions.",
      "chat_with_us": "Discutez Avec Nous",

      "about_our_company": "À Propos De Notre Entreprise",

      // service layout,
      "last_act_rank": "Rang Acte Précédent",
      "last_season_rank": "Rang Saison Précédente",
      "current_rank": "Rang Actuel",
      "current_rating": "Rang Actuel",
      "current_mastery": "Maîtrise actuelle",
      "current_level": "Niveau Actuel",
      "desired_badge": "Badge Désirée",
      "desired_amount": "Montant Désiré",
      "desired_rating": "Rang Désiré",
      "desired_mastery": "Maîtrise Désirée",
      "desired_level": "Niveau Désiré",
      "desired_legend": "Légende Désirée",

      // plays of the week,
      "view_more_on": "Voir plus sur",

      // order-flow:,
      "getting_your_order": "Obtenir votre commande",
      "start_ranking": "Commencez à monter de rangs en 3 simple étapes",
      "customize_your_service": "Personnalisez Votre Service",
      "complete_your_payment": "Effectuez votre paiement",
      "select_your_desired_payment_method": "Sélectionnez la méthode de paiement souhaitée. Nous prenons en charge Google Pay, Apple Pay, Visa Stripe et PaySafeCard, entre autres.",
      "start_ranking_up": "Commencez à monter de rangs",
      "order_ready_to_go": "Maintenant que votre commande est prête, un booster vous sera attribué immédiatement. Il est temps de gravir les rangs!",

      // reviews,
      "gg_boost_providing": "GGBoost: L\'excellence depuis",
      "years": "Années",
      "with_over": "Avec Plus De",
      "customer_reviews": "Évaluations de Clients",
      "show_more": "Montrer Plus",

      // buy-authenticate,
      "welcome_back": "Bienvenue à Nouveau",
      "please_login_or_register": "Veuillez vous connecter ou vous inscrire pour continuer!",
      "almost_there": "Vous y êtes presque!",
      "please_login_or_register_buy": "Veuillez vous inscrire ou vous connecter avant de passer commande!",
      "continue_with": "Continuez Avec",
      "register_with": "S\'inscrire Avec ",
      "or": "ou",
      "forgot_password": "Mot de pass oublié?",
      "have_an_account": "Vous avez un compte? Connectez-vous!",
      "duo_boosting_text": "Jouez sur votre compte avec l\'un de nos boosters.",
      "badges_boosting_text": "Vous débloquerez le Badge",
      "for": "pour",

      "solo_boosting_text": "L\'un de nos boosters jouera sur votre compte.",
      "division_boosting_text": "Garantissez le rang que vous désirez.",
      "premier_queue": "Vous avez sélectionné le mode  rang Premier pour votre boost.",
      "competitive_queue": "Vous avez sélectionné le mode compétitif pour votre boost.",
      "rating_boost": "Garantissez votre rang CS souhaité.",
      "placement_boosting_lol": "Nombre souhaité de parties de placement avec un taux de victoire minimum garanti de 70%.",
      "placement_boosting_valorant": "Nombre souhaité de parties de placement avec un taux de victoire minimum garanti de 80%.",
      "placement_boosting_tft": "Nombre souhaité de parties de placement avec une moyenne garantie de 3ème place ou plus.",
      "placement_boosting_apex": "Nombre souhaité de parties provisoires avec une moyenne garantie de la deuxième place ou plus.",
      "placement_boosting_cs2_desired": "Nombre souhaité de",
      "placement_boosting_cs2_queue_premier": "Premier",
      "placement_boosting_cs2_queue_competitive": "Compétitif",
      "placement_boosting_cs2_wins": "Victoire en placement.",

      "free": "Gratuit",

      "duo": "Duo",
      "duo_desc": "Vous faites équipe avec un booster",
      "solo": "Solo",
      "solo_desc": "Un Booster joue sur votre compte",
      "not_available": "Non Disponible",

      "choose_roles": "Choisissez vos Roles",
      "and_champs": "& Champions",
      "roles_tooltip": "Indiquez les rôles et les champions que vous souhaitez utiliser lorsque vous jouez avec votre booster.",

      "choose_agents": "Choisissez vos Agents",
      "agents_tooltip": "Spécifiez les agents que vous souhaitez utiliser lorsque vous jouez avec votre booster.",

      "choose_heroes": "Choisissez vos Héros",
      "choose_heroes_tooltip": "Indiquez les Héros que vous souhaitez utiliser lorsque vous jouez avec votre booster.",
      "choose_heroes_tooltip_solo": "Indiquez les Héros que vous souhaitez que le booster utilise, s'ils ne sont pas instantanément verrouillés.",

      "offline_vpn": "Mode Hors-Ligne + VPN Avancé",
      "offline_tooltip": "Apparaître hors ligne tout en jouant + VPN avec détection automatique de la localisation",

      "coaching": "Éléments de coaching",
      "coaching_tooltip": "Demandez des conseils sur la manière d\'améliorer vos performances en mode classé.",

      "express_priority": "Priorité express",
      "express_priority_tooltip": "Passez à l\'option Express pour bénéficier d\'une plus grande priorité et rapidité d\'exécution.",

      "use_your_credits": "Utilisez vos crédits",
      "use_your_discounts": "Utilisez vos réductions",

      "use_global_discount": "Utilisez Réduction Globale",
      "service_discount": "Utilisez Réduction Service",

      "total_price": "Prix Total",

      "excellent": "Excellent",

      "available_credits": "Crédits Disponibles",
      "order_price": "Prix",
      "credits_used": "Crédits Utilisés",
      "order_details": "Détails de la commande",
      "use_gg_credits": "Utilisez les Crédits GGBoost",

      "select_your_order_details": "Sélectionnez les détails de votre commande",

      // flag,
      "ready_5_min": "Prêt en 5 minutes",
      "avg_comp_time": "Temps Moyen de Réalisation",

      "hour": "heure",
      "hours": "heures",
      "day": "jour",
      "days": "jours",

      "comp_queue": "Mode Compétitif",
      "queue": "Mode",
      "queue_cs": "Mode",

      "server": "Serveur",
      "platform": "Plateforme",
      "region": "Région",
      "map": "Map",

      "estimated_time_left": "Temps Restant Estimé",

      "select_rank": "Sélectionnez le Rang",
      "select_rank_desired": "Sélectionnez le Rang Désiré",
      "select_queue": "Sélectionnez Mode",
      "select_region": "Sélectionnez Votre Région",

      "select_current_lp": "Sélectionnez votre LP actuelle",
      "select_current_rr": "Sélectionnez votre RR actuelle",

      "select_last_season_rank": "Sélectionnez votre Rang de la Saison Précédente",

      "current_lp": "LP Actuel",
      "current_rp": "RP Actuel",
      "current_rr": "RR Actuel",
      "current_vp": "VP Actuel",

      "marks": "Marks",

      // trustpilot reviews component,
      "trustpilot_reviews_title": "Des Milliers d\'Avis Vérifiés",
      "trustpilot_reviews_subtitle": "Découvrez pourquoi plus de 150 000 joueurs nous font confiance",

      // apex page,
      "coming_soon": "prochainement",

      // payment-success,
      "processing_payment": "Traitement du Paiement",

      // customer order page,
      "pause_order": "Mettre la commande en pause",
      "unpause_order": "Reprendre la commande",
      "spectate_match": "Assistez au Match",
      "review_order": "Voir la commande",

      // MISSING
      "select_desired_map": "Sélectionnez votre carte souhaitée",

      "safe_service": "Service Sécurisé",
      "fast_completion": "Exécution Rapide",
      "money_back": "Garantie de Remboursement",
      "support_247": "Support 24/7",

      "select_current_rp": "Sélectionnez votre RP actuel",
      "select_platform": "Sélectionnez la plateforme",

      "account_information": "Informations sur le compte",
      "additional_info": "Nous avons besoin d'informations supplémentaires avant de pouvoir commencer votre commande",
      "2fa_notification": "Veuillez vous assurer que l'authentification à deux facteurs est DÉSACTIVÉE sur votre compte, sinon, le booster ne pourra pas se connecter.",

      "application": "Demande",
      "your_profile_id": "Votre ID de profil",
      "assign_favorite_booster_label": "Attribuer un booster préféré",
      "no_favorite_boosters": "Vous n'avez pas encore de boosters préférés.",
      "first_booster": "Premier booster disponible",
      "online_boosters": "Boosters actuellement en ligne",
      "offline_boosters": "Boosters actuellement hors ligne",
      "last_rating": "Dernière évaluation",
      "order_notes": "Notes de Commande",
      "order_notes_placeholder": "Remplissez avec tout détail pertinent",
      "customize_order": "Personnalisez votre commande",
      "playstyle_info": "Sélectionnez vos préférences de style de jeu que le booster doit suivre",
      "please_fill": "Veuillez remplir tous les champs marqués avec *",
      "by_clicking": "En cliquant sur 'Sauvegarder et Commencer la Commande', vous acceptez les",
      "and": "et",

      "save_start": "Sauvegarder et Commencer la Commande",
      "privacy_policy": "Politique de confidentialité",
      "terms_use": "Conditions d'utilisation",

      "main_role": "rôle principal",
      "select_main_role": "Sélectionnez votre rôle principal",
      "secondary_role": "rôle secondaire",
      "select_secondary_role": "Sélectionnez votre rôle secondaire",
      "add": "ajouter",

      "select_your_champions": "Sélectionnez vos Champions",
      "search": "Rechercher",
      "confirm_selection": "Confirmer la sélection",

      "error": "Erreur",
      "information_error_msg": "Nous ne pouvons soit pas trouver votre Riot ID, soit une erreur est survenue pendant le processus de recherche ! Si cela persiste, veuillez contacter notre support client !",

      "information_mismatch": "Incompatibilité d'informations",
      "information_mismatch_msg": "Votre rang ne correspond pas à la commande que vous avez achetée ! Veuillez confirmer votre Riot ID !",
      "order_starting_rank": "Rang de départ de la commande",
      "edit_riot_id": "Modifier le Riot ID",

      "verify_your_summoner": "Vérifiez votre Riot ID",
      "level": "Niveau",
      "confirm_summoner": "Confirmer le Riot ID",

      "order_converted": "Votre commande a été convertie.",
      "order_chargeback": "Votre commande a été annulée.",
      "order_refunded": "Votre commande a été remboursée.",
      "order_pending": "Votre paiement est en attente.",
      "order_rejected": "Votre paiement a été rejeté.",
      "order_active_progress": "Votre commande est en cours.",
      "order_active_action": "Votre action est requise !",
      "order_completed": "Votre commande est terminée !",
      "order_paused": "Votre commande est en pause.",

      "order_converted_desc": "Votre commande a été convertie avec succès en crédits !",
      "order_chargeback_desc": "Contactez le support client pour plus d'informations !",
      "order_refunded_desc": "Nous vous avons émis un remboursement, cela peut prendre jusqu'à 24 heures !",
      "order_rejected_desc": "Votre paiement a été refusé par votre fournisseur !",
      "order_pending_desc": "Votre commande commencera dès que votre paiement sera traité !",
      "order_active_progress_desc": "Vous pouvez parler à votre booster dans le chat de la commande !",
      "order_active_action_desc": "Remplissez les champs ci-dessous pour commencer votre commande !",
      "order_completed_desc": "Merci de nous avoir choisis ! Veuillez nous laisser un avis !",
      "order_paused_desc": "Vous pouvez reprendre votre commande à tout moment !",

      "please_edit_credentials": "Veuillez modifier vos identifiants !",
      "please_edit_credentials_desc": "Cher client, les informations de connexion que vous avez fournies sont incorrectes.",
      "account_name": "Nom du compte",
      "account_password": "Mot de passe du compte",
      "hide": "Masquer",
      "show": "Afficher",
      "save_continue": "Sauvegarder et Continuer la Commande",

      "thank_you": "Merci d'avoir choisi GGBOOST !",
      "admin_buy_help_msg": "Qui a été l'agent qui vous a le plus aidé lors de l'achat de votre première commande ?",
      "admin_buy_help_none": "Aucun, j'ai acheté par moi-même",

      "match_history": "Historique des parties",
      "loading": "chargement",

      "waiting_for_booster": "En attente du booster",
      "currently_online": "Actuellement en ligne",
      "currently_away": "Actuellement absent",
      "currently_offline": "Actuellement hors ligne",
      "textbox_placeholder": "Tapez ici et appuyez sur Entrée",

      "auto_swap": "Auto Swap",
      "auto_swap_tooltip": "Changement automatique si le booster est hors ligne.",
      "click_more": "Cliquez pour voir plus.",
      "alert_booster": "Alerter le booster",
      "request_new_booster": "Demander un nouveau booster",
      "request_new_booster_tooltip": "La commande doit être en pause pour pouvoir demander un nouveau booster !",

      "order_id": "ID de commande",
      "order_type": "Type de commande",
      "order_status": "État de la commande"
    },
    "de": {
      "auto_swap_set_order_tooltip": "Aktiviert sich, wenn der Booster länger als 90 Minuten offline ist. Gilt nicht für Favoriten!",

      "no_recent_games": "Keine kürzlichen Spiele." ,

      "your_favorites": "Deine Favoriten",
      "recently_played_with": "Kürzlich Zusammen Gespielt",
      "games": "Spiele",
      "added_on": "hinzugefügt am",

      "favorite_booster_add_tooltip": "Zu Favoriten hinzufügen",
      "favorite_booster_remove_tooltip": "Von Favoriten entfernen",
      "favorite_booster_added": "Lieblingsbooster hinzugefügt",
      "favorite_booster_removed": "Lieblingsbooster entfernt",
      "favorite_booster_error_customer_not_found": "Kunde nicht gefunden",
      "favorite_booster_error_already_exists": "Booster befindet sich bereits in Ihrer Favoritenliste",
      "favorite_booster_error_booster_not_found": "Booster nicht gefunden",
      "error_server_or_network": "Server- oder Netzwerkfehler",
      "error_unknown": "Unbekannter Fehler",

      "starting": "Start",
      "current": "Aktuell",
      "desired": "Gewunscht",

      "starting_points": "Startpunkte",
      "starting_lp": "Start-LP",
      "starting_rp": "Start-RP",
      "starting_rr": "Start-RR",
      "starting_vp": "Start-VP",

      "champ_selection_info": "Die Auswahl von 4 oder mehr Champions ist kostenlos. Eine Gebühr von {{REPLACE}}% wird für die Auswahl von 1 bis 3 Champions hinzugefügt.",
      "current_selection": "Aktuelle Auswahl",
      "youll_earn": "Sie erhalten",
      "crypto_payment_info": "Diese Zahlung kann einige Minuten dauern.",
      "subtotal": "Zwischensumme",
      "complete_purchase": "Kauf abschließen",
      "global_discount": "Globaler Rabatt",
      "platform_fee_tooltip": "Finanziert die Sicherheit der Gelder und kontinuierliche Verbesserungen der Website.",
      "platform_fee": "Plattformgebühr",
      "transaction_fee_tooltip": "Gebühr zur Abdeckung der sicheren Zahlungsabwicklung.",
      "transaction_fee": "Transaktionsgebühr",
      "campaign_discount": "Kampagnenrabatt",

      "unranked_wins": "Gewünschte Anzahl an ungewerteten Siegen.",

      "select_character_lol" : "Wählen Sie Ihren Champion",
      "select_characters_lol" : "Wählen Sie Ihre Champions",
      "select_character_wild-rift" : "Wählen Sie Ihren Champion",
      "select_characters_wild-rift" : "Wählen Sie Ihre Champions",
      "select_character_valorant" : "Wählen Sie Ihren Agenten",
      "select_characters_valorant" : "Wählen Sie Ihre Agenten",
      "select_character_legends" : "Wählen Sie Ihren Legenden",
      "select_characters_legends" : "Wählen Sie Ihre Legenden",
      "select_character_marvel": "Wählen Sie Ihren Héros",
      "select_characters_marvel": "Wählen Sie Ihre Héros",

      "character_label_lol": "Champions",
      "character_label_wild-rift": "Champions",
      "character_label_valorant": "Agenten",
      "character_label_apex": "Legenden",
      "character_label_marvel": "Helden",

      "game_selector_title_marvel": "Marvel Rivals Boost Services",
      "your_current_points": "Ihre aktuellen Punkte",
      "current_points": "Aktuelle Punkte",
      "select_current_points": "Wählen Sie Ihre aktuellen Punkte",

      "last_known_rank": "Zuletzt Bekannter Rang",
      "last_known_rank_tooltip": "Wenn Sie zuvor eingestuft waren, aber nicht in der letzten Saison, wählen Sie Ihren zuletzt aufgezeichneten Rang aus.",

      "no_posts": "Noch keine Beiträge!",
      "streaming_tooltip": "Schau deinem Booster live auf YouTube/Twitch in einem privaten Stream zu",
      "voice_tooltip": "Starte eine Sprachkommunikation mit deinem Booster während des Spielens",

      // MERGE MISSING
      "&_summoner_spells": "& Beschwörerzauber",
      "choose_champions": "Wähle deine Champions",
      "choose_champions_tooltip": "Sag, welche Champions du mit deinem Booster spielen möchtest.",
      "choose_legends": "Wähle deine Legenden",
      "choose_legends_tooltip": "Sag, welche Legenden du mit deinem Booster spielen möchtest.",
      "choose_legends_tooltip_solo": "Wähle aus, welche Legenden der Booster spielen soll, falls sie nicht sofort gelockt werden.",
      "solo_only": "Nur Solo",
      "solo_only_tooltip": "Stell sicher, dass dein Booster mit niemand anderem spielt.",
      "streaming_voice": "Streaming + Stimme",

      "register_account": "Konto registrieren",
      "recover_password": "Passwort wiederherstellen",

      "mastery_boosting_lol": "Garantierter Weg, um dein gewünschtes Meisterschaftslevel mit einem bestimmten Champion zu erreichen.",
      "leveling_boosting": "Garantierter Weg, um dein gewünschtes Account-Level durch ungewertete Spiele zu erreichen.",
      "normal_wins_boosting_lol": "Gewünschte Anzahl an ungewerteten Siegen.",
      "unrated_wins_valorant": "Gewünschte Anzahl an ungewerteten Siegen.",
      "games_boosting": "Gewünschte Anzahl an Ranglistenspielen mit garantiert guter Booster-Leistung.",
      "net_wins_boosting": "Garantierte positive Anzahl an Ranglistensiegen. Für jede Niederlage gibt's einen zusätzlichen Sieg.",
      "mastery_champ_lol": "Dein ausgewählter Champion für das Meisterschafts-Boosting.",
      "map_cs": "Deine ausgewählte Karte für das kompetitive Boosting.",

      "your_current_lp_amount": "Deine aktuelle LP-Zahl.",
      "your_current_lp_amount_eq": "Deine aktuelle LP-Zahl ist gleich oder höher als",
      "your_current_rr_amount": "Deine aktuelle RR-Zahl.",
      "your_current_rr_amount_eq": "Deine aktuelle RR-Zahl ist gleich oder höher als",
      "your_current_rp_amount": "Deine aktuelle RP-Zahl.",
      "your_current_marks": "Deine aktuellen Marken.",

      "tip_tooltip": "Du gibst deinem Booster ein Trinkgeld. Trinkgelder sind nicht verpflichtend, aber sehr willkommen. Merci!",
      "express_tooltip": "Top-Priorität und schnellere Erledigung, rund um die Uhr.",
      "cashback_tooltip": "an Cashback für deine nächsten Einkäufe!",
      "character_tooltip": "Wähle deinen bevorzugten",
      // "streaming_tooltip": "Schau dir die Matches live an mit unserem personalisierten Streaming-Erlebnis.",

      "valorant_character_tooltip": "Sie haben Ihre bevorzugten Agenten ausgewählt.",
      "apex_character_tooltip": "Sie haben Ihre bevorzugten Legenden ausgewählt.",
      "lol_character_tooltip": "Sie haben Ihre bevorzugten Champions ausgewählt.",
      "marvel_character_tooltip": "Sie haben Ihre bevorzugten Helden ausgewählt.",

      "additions": "Extras",

      "playstyle_info_duos": "Lass den Booster deine persönlichen Vorlieben wissen.",

      "choose_roles_tooltip_solo": "Wähle aus, welche Rollen der Booster nutzen soll, wenn er auf deinem Account spielt.",
      "choose_champions_tooltip_solo": "Wähle aus, welche Champions der Booster spielen soll, falls sie nicht gewählt oder gebannt werden.",
      "choose_agents_tooltip_solo": "Wähle aus, welche Agenten der Booster spielen soll, falls sie nicht sofort gelockt werden.",


      // NEW MISSING
      "select_role_champions": "Wähle deine Rollen & Champions",
      "select_summoner_spell_keyset": "Wähle das Beschwörerzauber-Set",
      "confirm": "Bestätigen",

      "select_your_agents": "Wähle deine Agenten",

      "request_new_booster_desc": "Lass uns wissen, warum du einen neuen Booster möchtest. Wir werden dir in Kürze einen neuen booster zuweisen.",

      "request_reason": "Anfragegrund",

      "assign_favorite_booster": "Lieblings-Booster zuweisen",
      "assign_favorite_booster_desc": "Die Booster, denen du bei vorherigen Bestellungen positive Bewertungen gegeben hast, werden zu deiner Favoritenliste hinzugefügt.",
      "assign_booster": "Booster zuweisen",
      "booster_not_in_game": "Stelle sicher, dass dein Booster nicht im Spiel ist!",

      "request_booster_attention": "Booster-Aufmerksamkeit anfordern",
      "request_booster_attention_desc": "Benachrichtige den derzeit zugewiesenen Booster, dass du bereit bist, den Auftrag fortzusetzen.",
      "you_may_perform": "Du kannst diese Aktion bis zu",
      "per_day": "Mal pro Tag durchführen",

      "cancel": "Abbrechen",

      "tipping_options": "Trinkgeldoptionen",
      "tipping_options_no_boosters": "Es sieht so aus, als hättest du keine Booster-Historie für diesen Auftrag!",

      "use_credits": "Credits Verwenden",
      "custom_tip": "Anpassen",

      "auto_swap_desc": "Aktiviere 'Auto-Wechsel', und wir wechseln automatisch zu einem anderen Booster, wenn dein aktueller Booster offline geht für",
      "auto_swap_remember": "Denke daran, diese Funktion gilt nicht für Booster, die du persönlich aus deiner Favoritenliste ausgewählt hast.",
      "auto_swap_current_status": "Aktueller Status:",
      "auto_swap_booster_swap": "Booster wird gewechselt in",
      "auto_swap_information_updated": "Informationen werden alle 5 Minuten aktualisiert.",
      "auto_swap_enable": "Aktiviere 'Auto-Wechsel'",
      "auto_swap_disable": "Deaktiviere 'Auto-Wechsel'",

      "pages": "Seiten",
      "contact": "Kontakt",

      "review_your_booster": "Bewerte deinen Booster",
      "review_your_boosters": "Bewerte deine Booster",
      "review_usage": "Verwende den Sternbewertungsselektor oben, um deinen Booster zu bewerten",
      "review_please_write": "Bitte schreibe deine Bewertung",
      "review_no_booster": "Es sieht so aus, als hättest du keinen Booster in diesem Auftrag!",

      "looks_like_no_order":"Sieht so aus, als hättest du hier noch keine Bestellungen!",
      "press_purchase_boost":"Drücke auf \"Boost kaufen\" auf der rechten Seite, um jetzt deinen gewünschten Rang zu erreichen!",

      //-------------------------------------------

      "report_translation": "Übersetzung melden",
      "open": "Offen",
      "closed": "Geschlossen",

      "ticket_add_answer": "Antwort hinzufügen",
      "ticket_details": "Ticketdetails",
      "ticket_close": "Ticket schließen",
      "sent_at": "Gesendet am",
      "customer": "Kunde",

      "legend": "Legende",
      "badge" : "Abzeichendge",

      "country_selection": "Land Auswählen",

      "no_boosters_reviewed": "Du hast noch keine Bewertungen für Booster abgegeben!",

      "no_customizations": "Du hast noch keine Bewertungen für Anpassungen abgegeben!",

      // HIDDEN
      "en_flag": "US-Flaggenbild",
      "de_flag": "Deutsches Flaggenbild",
      "fr_flag": "Französisches Flaggenbild",
      "es_flag": "Spanisches Flaggenbild",
      "pt_flag": "Portugiesisches Flaggenbild",

      // NEW
      "use_your_spendables": "Nutze deine Guthaben",
      "store_credits": "Nutze Ladenkredite",

      "select_current_marks": "Wähle deine aktuellen Ranglistenzeichen aus",

      "select_badge": "Abzeichen Wählen",
      "popular_badges": "Beliebte Abzeichen",
      "all_badges": "Alle Abzeichen",

      "select_character_apex": "Wähle deine Legende aus",
      "select_characters_apex": "Wähle deine Legenden aus",
      "select": "Select",

      "tip_booster": "Trinkgeld geben",

      "login":"Anmelden",
      "username": "Benutzername",
      "password": "Passwort",

      // attention texts,
      "new_episode": "Neue Episode",
      "new_split": "Neuer Split",
      "new_act": "Neuer Akt",
      "new_set": "Neuer Set",

      // my-account,
      "purchase_boost": "Boost kaufen",
      "create_ticket": "Ticket erstellen",
      "active_orders": "Aktive Bestellungen",
      "order_history": "Bestellverlauf",
      "support": "Tickets",
      "spendables": "Guthaben",
      "edit_account": "Mein Konto",
      "logout": "Abmelden",

      "subject": "Thema",
      "status": "Status",
      "created_at": "Erstellt am",

      "service": "Service",
      "purchase_date": "Kaufdatum",
      "details": "Details",

      // new support ticket,
      "new_support_ticket": "Neues Support-Ticket",
      "category": "Kategorie",
      "select_category": "Kategorie auwählen",
      "bug_report": "Fehlerbericht",
      "general_question": "Allgemeine Frage",
      "billing_financial": "Abrechnung",
      "other": "Andere",
      "problem_description": "Beschreibung",
      "submit_ticket": "Ticket Einreichen",

      // spendables,
      "amount": "Betrag",
      "currency": "Währung",
      "available_discounts": "Vefügbare Rabatte",

      "home_page_title_page": "GGBoost Blog - Top Gaming und Boosting News - Seite",
      "home_page_title": "Meistverkauftes League of Legends Boosting & Valorant Elo Boost",
      "home_page_desc": "GGBoost ist Marktführer im Bereich Elo-Boosting für League of Legends und Valorant. Beginne noch heute, deinen Rang zu erhöhen und dein Spielerlebnis zu verbessern!",

      "blog_page_title": "GGBoost Blog - Top Gaming und Boosting News",
      "blog_page_title_page": "GGBoost Blog - Top Gaming und Boosting News - Seite",
      "blog_page_desc": "Mit dem GGBoost.com-Blog bist du immer über die neuesten Spiele informiert. Hier findest du Artikel, Einsichten und Trends für Spielefans.",

      "boosting_services": "Boosting Services",
      "boosting_services_page_title": "Boosting Services - GGBOOST",

      "blog": "Blog",
      "reviews": "Bewertungen",
      "tutorial": "Tutorial",
      "my_account": "Mein Konto",
      "our_games": "Unsere Spiele",
      "best_boost": "Die beste Elo Boosting Experience",
      "get_your": "Hol dir deinen",
      "desired_rank": "Gewünschten Rang",
      "now!": "jetzt!",
      "check_tutorial": "Tutorial ansehen",
      "buy_boost_now": "Boost Jetzt Kaufen",

      "game_selector_title_lol": "Elo Boost Services",
      "game_selector_title_valorant": "Valorant Boost Services",
      "game_selector_title_tft": "TFT Boost Services",
      "game_selector_title_wild_rift": "Wild Rift Boost Services",
      "game_selector_title_cs2": "CS2 Boost Services",
      "game_selector_title_apex": "APEX Boost Services",

      "best_selling": "Meistverkaufte League of Legends & Valorant Boosting Services",
      "best_selling_desc": "Wir sind der Meinung, dass jeder Kunde ein besonderes, auf seine Bedürfnisse zugeschnittenes Erlebnis verdient.",
      "the_safest": "Das sicherste",
      "the_safest_desc": "Wir sind unseren Kunden gegenüber verpflichtet: Wir bieten stets ein hohes Maß an Service und Sicherheit.",
      "trustworthy": "Vertrauenswürdig",
      "trustworthy_desc": "Wir freuen uns, die am besten bewertete Boosting-Website der Branche zu sein, mit einer erstaunlichen 5-Sterne-Bewertung auf Trustpilot.",
      "top_results": "Spitzenergebnisse",
      "top_results_desc": "Wir sind führend in der Boosting-Branche mit einer Gesamtgewinnquote von 87% über alle Dienstleistungen.",

      "unique": "Was macht GGBoost so einzigartig?",
      "ingame_chat_tracking": "In-Game und Lobby Chat Tracking",
      "ingame_chat_tracking_desc": "Wir sind das einzige Unternehmen auf dem Markt, das in der Lage ist, alles zu überwachen, was der Booster in deinem Konto tut, um sicherzustellen, dass es immer sicher ist.",
      "login_secure": "Dein Login ist zu jeder Zeit sicher",
      "login_secure_desc": "Auf deine privaten Logindaten hat der Booster keinen Zugriff, unser einzigartiges Programm registriert sie automatisch.",
      "advanced_vpn": "Fortgeschrittenes VPN",
      "advanced_vpn_desc": "Mit unserem Advanced VPN wird dein Booster automatisch mit einer Region in deiner Nähe verbunden, sodass es so aussieht, als würdest du von zu Hause aus spielen.",

      "your_boost_your_rules": "Dein Boost Deine Regeln",
      "fully_customizable": "Vollständig anpassbar",
      "fully_customizable_desc": "Wähle deine bevorzugten Champions/Agenten, Rollen und deine übliche Beschwörerzauber-Reihenfolge, völlig kostenlos. Mit unserer Express-Plus-Option kannst du den Bestellvorgang beschleunigen.",
      "favorite_boosters": "Lieblings-Booster",
      "favorite_boosters_desc": "Klicke auf das Herzsymbol in deinem Bestell-Dashboard, um einen Booster zu deinen Favoriten hinzuzufügen. Bei zukünftigen Bestellungen kannst du sehen, wann sie verfügbar sind und sie einfach zuweisen.",
      "live_chat_schedule": "Live Chat und Zeitplan",
      "live_chat_schedule_desc": "Kommuniziere in Echtzeit mit deinem Booster und plane deine Duo-Spielsitzungen.",

      "read_more": "Mehr lesen",
      "previous_page": "Vorherige Seite",
      "next_page": "Nächste Seite",
      "read_more_about": "Mehr lesen über",

      "ready_to_start": "Bereit anzufangen? Jetzt kaufen!",

      "what_our_customers_say": "Was unsere Kunden sagen",

      "live_support": "Live Support",
      "got_any_questions": "Eine Frage?",
      "got_any_questions_desc": "Unser engagiertes Kundendienstteam steht dir rund um die Uhr zur Verfügung und hilft dir bei allen Fragen.",
      "chat_with_us": "Chatte mit uns",

      "about_our_company": "Über unser Unternehmen",

      // service layout,
      "last_act_rank": "Letzter Akt Rang",
      "last_season_rank": "Letzter Saison-Rang",
      "current_rank": "Aktueller Rang",
      "current_rating": "Aktuelles Rating",
      "current_mastery": "Aktuelles Mastery",
      "current_level": "Aktuelles Level",
      "desired_badge": "Gewünschtes Abzeichen",
      "desired_amount": "Gewünschter Betrag",
      "desired_rating": "Gewünschtes Rating",
      "desired_mastery": "Gewünschtes Mastery",
      "desired_level": "Gewünschtes Level",
      "desired_legend": "Gewünschte Legende",

      // plays of the week,
      "view_more_on": "Mehr anasehen auf",

      // order-flow:,
      "getting_your_order": "Deine Bestellung erhalten",
      "start_ranking": "Starte dein Ranking in 3 einfachen Schritten",
      "customize_your_service": "Deinen Service anpassen",
      "complete_your_payment": "Zahlung abschließen",
      "select_your_desired_payment_method": "Wähle die gewünschte Zahlungsart aus. Wir unterstützen unter anderem Google Pay, Apple Pay, Visa, Stripe und PaySafeCard.",
      "start_ranking_up": "Beginne mit dem Ranking",
      "order_ready_to_go": "Jetzt, wo deine Bestellung fertig ist, bekommst du sofort einen Booster zugeteilt. Jetzt kann es losgehen!",

      // reviews,
      "gg_boost_providing": "GGBoost: Exzellenz seit",
      "years": "Jahre",
      "with_over": "mit über",
      "customer_reviews": "Kundenbewertungen",
      "show_more": "Mehr zeigen",

      // buy-authenticate,
      "welcome_back": "Willkommen zurück",
      "please_login_or_register": "Bitte melde dich an oder registriere ein Konto, um fortzufahren!",
      "almost_there": "Du bist fast am Ziel!",
      "please_login_or_register_buy": "Bitte registriere dich oder logge dich ein, bevor du deine Bestellung aufgibst!",
      "continue_with": "Fortfahren mit",
      "register_with": "Registrieren mit",
      "or": "oder",
      "forgot_password": "Passwort vergessen?",
      "have_an_account": "Du hast ein Konto? Einloggen!",
      "duo_boosting_text": "Spiele auf deinem Account zusammen mit einem unserer Booster.",
      "badges_boosting_text": "Du wirst das Abzeichen freischalten",
      "for": "für",

      "solo_boosting_text": "Einer unserer Booster wird auf deinem Konto spielen.",
      "division_boosting_text": "Sichere dir deinen gewünschten Rang.",
      "premier_queue": "Du hast die Premier Ranking Queue für deinen Boost ausgewählt.",
      "competitive_queue": "Du hast die Competitive Ranking Queue für deinen Boost ausgewählt.",
      "rating_boost": "Garantiere dir dein gewünschtes CS Rating.",
      "placement_boosting_lol": "Gewünschte Anzahl von Platzierungsspielen mit einer garantierten Gewinnquote von mindestens 70%.",
      "placement_boosting_valorant": "Gewünschte Anzahl von Platzierungsspielen mit einer garantierten Mindestgewinnquote von 80%.",
      "placement_boosting_tft": "Gewünschte Anzahl von Platzierungsspielen mit einem garantierten Durchschnitt von Platz 3 oder höher.",
      "placement_boosting_apex": "Gewünschte Anzahl an vorläufigen Spielen mit einem garantierten Durchschnitt von Platz 2 oder höher.",
      "placement_boosting_cs2_desired": "Gewünschte Anzahl von",
      "placement_boosting_cs2_queue_premier": "Premier",
      "placement_boosting_cs2_queue_competitive": "Competitive",
      "placement_boosting_cs2_wins": "Placement Wins.",

      "free": "Gratis",

      "duo": "Duo",
      "duo_desc": "Du spliest mit einem Booster",
      "solo": "Solo",
      "solo_desc": "Booster spielt auf deinem Konto",
      "not_available": "Nicht verfügbar",

      "choose_roles": "Wähle deine Rollen",
      "and_champs": "& Champions",
      "roles_tooltip": "Gib an, welche Rollen du beim Spielen mit deinem Booster verwenden möchtest.",

      "choose_agents": "Wähle deine Agenten",
      "agents_tooltip": "Gib an, welche Agenten du beim Spielen mit deinem Booster verwenden möchtest.",

      "choose_heroes": "Wähle deine Helden",
      "choose_heroes_tooltip": "Gib an, welche Helden du beim Spielen mit deinem Booster verwenden möchtest.",
      "choose_heroes_tooltip_solo": "Gib an, welche Helden der Booster verwenden soll, falls sie nicht sofort gesperrt werden.",

      "offline_vpn": "Fortgeschrittenes VPN",
      "offline_tooltip": "Beim Spielen offline erscheinen + VPN mit automatischer Standorterkennung",

      "coaching": "Coaching-Elemente",
      "coaching_tooltip": "Lass dich beraten, wie du deine Leistung in einem Ranking verbessern kannst.",

      "express_priority": "Express Priorität",
      "express_priority_tooltip": "Upgrade auf Express für höhere Priorität und schnellere Abwicklung.",

      "use_your_credits": "Verwende deine Credits",
      "use_your_discounts": "Nutze deine Rabatte",

      "use_global_discount": "Globalen Rabatt Nutzen",
      "service_discount": "Service-Rabatt Nutzen",

      "total_price": "Gesamtpreis",

      "excellent": "Ausgezeichnet",

      "available_credits": "Verfügbare Credits",
      "order_price": "Bestellpreis",
      "credits_used": "Verwendete Credits",
      "order_details": "Details zur Bestellung",
      "use_gg_credits": "GGBoost Credits verwenden",

      "select_your_order_details": "Wahle deine Bestelldetails",

      // flag,
      "ready_5_min": "Bereit in 5 Minuten",
      "avg_comp_time": "Durchschnittliche Fertigstellungszeit",

      "hour": "Stunde",
      "hours": "Stunden",
      "day": "Tag",
      "days": "Tage",

      "comp_queue": "Competitive Queue",
      "queue": "Queue",
      "queue_cs": "Modus",

      "server": "Server",
      "platform": "Plattform",
      "region": "Region",
      "map": "Map",

      "estimated_time_left": "Geschätzte verbleibende Zeit",

      "select_rank": "Rang wählen",
      "select_rank_desired": "Gewünschten Rang auswählen",
      "select_queue": "Select Queue",
      "select_region": "Wähle deine Region",

      "select_current_lp": "Wähle deine aktuelle LP",
      "select_current_rr": "Wähle deine aktuelle RR",

      "select_last_season_rank": "Wähle Rang der letzten Saison",

      "current_lp": "Aktueller LP",
      "current_rp": "Aktueller RP",
      "current_rr": "Aktueller RR",
      "current_vp": "Aktueller VP",

      "marks": "Marks",

      // trustpilot reviews component,
      "trustpilot_reviews_title": "Tausende von geprüften Bewertungen",
      "trustpilot_reviews_subtitle": "Erfahre, warum über 150.000 Spieler uns vertrauen",

      // apex page,
      "coming_soon": "Bald verfügbar",

      // payment-success,
      "processing_payment": "Bearbeitung der Zahlung",

      // customer order page,
      "pause_order": "Bestellung pausieren",
      "unpause_order": "Fortsetzen",
      "spectate_match": "Spiel zuschauen",
      "review_order": "Bestellung überprüfen",

      // MISSING
      "select_desired_map": "Wähle deine gewünschte Karte aus",

      "safe_service": "Sicherer Service",
      "fast_completion": "Schnelle Fertigstellung",
      "money_back": "Geld-zurück-Garantie",
      "support_247": "24/7 Unterstützung",

      "select_current_rp": "Wähle dein aktuelles RP aus",
      "select_platform": "Plattform Wählen",

      "account_information": "Konto Informationen",
      "additional_info": "Wir brauchen noch einige zusätzliche Informationen, bevor wir deine Bestellung starten können.",
      "2fa_notification": "Bitte stelle sicher, dass die Zwei-Faktor-Authentifizierung in deinem Konto DEAKTIVIERT ist, sonst kann der Booster sich nicht einloggen.",

      "application": "Bewerbung",
      "your_profile_id": "Deine Profil-ID",
      "assign_favorite_booster_label": "Wähle bevorzugten Booster zu",
      "no_favorite_boosters": "Du hast noch keine bevorzugten Booster.",
      "first_booster": "Erster verfügbarer Booster",
      "online_boosters": "Derzeit online Booster",
      "offline_boosters": "Derzeit offline Booster",
      "last_rating": "Letzte Bewertung",
      "order_notes": "Bestellnotizen",
      "order_notes_placeholder": "Fülle mit allen relevanten Details aus",
      "customize_order": "Passe deine Bestellung an",
      "playstyle_info": "Wähle deine Spielstil-Vorlieben aus, die der Booster befolgen muss.",
      "please_fill": "Bitte fülle alle mit * markierten Felder aus",
      "by_clicking": "Durch Klicken auf 'Speichern & Bestellung starten' stimmst du den",
      "and": "und",

      "save_start": "Speichern & Bestellung starten",
      "privacy_policy": "Datenschutzrichtlinie",
      "terms_use": "Nutzungsbedingungen",

      "main_role": "Hauptrolle",
      "select_main_role": "Wähle deine Hauptrolle aus",
      "secondary_role": "Nebenrolle",
      "select_secondary_role": "Wähle deine Nebenrolle aus",
      "add": "und",

      "select_your_champions": "Wähle deine Champions aus",
      "search": "Suche",
      "confirm_selection": "Auswahl bestätigen",

      "error": "Fehler",
      "information_error_msg": "Wir können entweder deine Riot-ID nicht finden oder es ist ein Fehler beim Suchvorgang aufgetreten! Wenn das weiterhin passiert, kontaktiere bitte unseren Kundenservice!",

      "information_mismatch": "Informationen stimmen nicht überein",
      "information_mismatch_msg": "Dein Rang passt nicht zu deiner gekauften Bestellung! Bitte bestätige deine Riot-ID!",
      "order_starting_rank": "Start-Rang der Bestellung",
      "edit_riot_id": "Riot-ID bearbeiten",

      "verify_your_summoner": "Bestätige deinen Riot ID",
      "level": "Stufe",
      "confirm_summoner": "Riot ID bestätigen",

      "order_converted": "Deine Bestellung wurde umgewandelt.",
      "order_chargeback": "Deine Bestellung wurde zurückgebucht.",
      "order_refunded": "Deine Bestellung wurde erstattet.",
      "order_pending": "Deine Zahlung ist vorübergehend gesperrt.",
      "order_rejected": "Deine Zahlung wurde abgelehnt.",
      "order_active_progress": "Deine Bestellung ist in Bearbeitung.",
      "order_active_action": "Deine Aktion ist erforderlich!",
      "order_completed": "Deine Bestellung ist abgeschlossen!",
      "order_paused": "Deine Bestellung ist pausiert.",

      "order_converted_desc": "Deine Bestellung wurde erfolgreich in Credits umgewandelt!",
      "order_chargeback_desc": "Kontaktiere den Kundenservice für weitere Informationen!",
      "order_refunded_desc": "Wir haben dir eine Rückerstattung ausgestellt, die bis zu 24 Stunden dauern kann!",
      "order_rejected_desc": "Deine Zahlung wurde von deinem Anbieter abgelehnt!",
      "order_pending_desc": "Deine Bestellung beginnt, sobald deine Zahlung verarbeitet ist!",
      "order_active_progress_desc": "Du kannst mit deinem Booster im Bestell-Chat sprechen!",
      "order_active_action_desc": "Fülle die untenstehenden Felder aus, um deine Bestellung zu starten!",
      "order_completed_desc": "Danke, dass du uns gewählt hast! Bitte hinterlasse uns eine Bewertung!",
      "order_paused_desc": "Du kannst deine Bestellung jederzeit wieder fortsetzen!",

      "please_edit_credentials": "Bitte bearbeite deine Anmeldedaten!",
      "please_edit_credentials_desc": "Lieber Kunde, die Anmeldedaten, die du angegeben hast, sind falsch.",
      "account_name": "Kontoname",
      "account_password": "Kontopasswort",
      "hide": "Verbergen",
      "show": "Anzeigen",
      "save_continue": "Speichern & Bestellung fortsetzen",

      "thank_you": "Danke, dass du GGBOOST gewählt hast!",
      "admin_buy_help_msg": "Wer war der Agent, der dir beim Kauf deiner ersten Bestellung am meisten geholfen hat?",
      "admin_buy_help_none": "Niemand, ich habe selbst gekauft.",

      "match_history": "Spielverlauf",
      "loading": "laden",

      "waiting_for_booster": "Warten auf den Booster",
      "currently_online": "Derzeit online",
      "currently_away": "Derzeit abwesend",
      "currently_offline": "Derzeit offline",
      "textbox_placeholder": "Hier tippen und Enter drücken",

      "auto_swap": "Auto-Wechsel",
      "auto_swap_tooltip": "Automatischer Wechsel, wenn der Booster offline ist.",
      "click_more": "Klicken Sie für mehr Informationen.",
      "alert_booster": "Booster alarmieren",
      "request_new_booster": "Neuen Booster anfordern",
      "request_new_booster_tooltip": "Die Bestellung muss pausiert sein, um einen neuen Booster anfordern zu können!",

      "order_id": "Bestellnummer",
      "order_type": "Bestelltyp",
      "order_status": "Bestellstatus",
    },
    "pt": {
      "auto_swap_set_order_tooltip": "Ativa-se quando o booster fica offline por mais de 90 minutos. Não se aplica aos favoritos!",

      "no_recent_games": "Nenhuma jogada recente.",

      "your_favorites": "Os teus favoritos",
      "recently_played_with": "Jogado Recentemente Com",
      "games": "jogos",
      "added_on": "adicionado em",

      "favorite_booster_add_tooltip": "Adicionar aos favoritos",
      "favorite_booster_remove_tooltip": "Remover dos favoritos",
      "favorite_booster_added": "Booster favorito adicionado",
      "favorite_booster_removed": "Booster favorito removido",
      "favorite_booster_error_customer_not_found": "Cliente não encontrado",
      "favorite_booster_error_already_exists": "O booster já está na sua lista de favoritos",
      "favorite_booster_error_booster_not_found": "Booster não encontrado",
      "error_server_or_network": "Erro de servidor ou rede",
      "error_unknown": "Erro desconhecido",

      "starting": "Inicial",
      "current": "Atual",
      "desired": "Desejado",

      "starting_points": "Pontos Iniciais",
      "starting_lp": "LP Inicial",
      "starting_rp": "RP Inicial",
      "starting_rr": "RR Inicial",
      "starting_vp": "VP Inicial",

      "champ_selection_info": "Selecionar 4 ou mais champions é grátis. Uma taxa de {{REPLACE}}% é adicionada se escolheres entre 1 a 3.",
      "current_selection": "Seleção Atual",
      "youll_earn": "Vais ganhar",
      "crypto_payment_info": "Este pagamento pode levar alguns minutos para ser processado.",
      "subtotal": "Subtotal",
      "complete_purchase": "Finalizar compra",
      "global_discount": "Desconto global",
      "platform_fee_tooltip": "Financia a segurança dos fundos e melhorias contínuas no site.",
      "platform_fee": "Taxa da plataforma",
      "transaction_fee_tooltip": "Taxa que cobre o processamento seguro de pagamentos.",
      "transaction_fee": "Taxa de transação",
      "campaign_discount": "Desconto de Campanha",

      "unranked_wins": "Número desejado de vitórias não ranked.",

      "select_character_lol" : "Selecionar Champion",
      "select_characters_lol" : "Selecionar Champions",
      "select_character_wild-rift" : "Selecionar Champion",
      "select_characters_wild-rift" : "Selecionar Champions",
      "select_character_valorant" : "Selecionar Agent",
      "select_characters_valorant" : "Selecionar Agents",
      "select_character_legends" : "Selecionar Legend",
      "select_characters_legends" : "Selecionar Legends",
      "select_character_marvel": "Selecionar Hero",
      "select_characters_marvel": "Selecionar Heroes",

      "character_label_lol": "Champions",
      "character_label_wild-rift": "Champions",
      "character_label_valorant": "Agents",
      "character_label_apex": "Legends",
      "character_label_marvel": "Heroes",

      "game_selector_title_marvel": "Serviços de Boost de Marvel Rivals",
      "your_current_points": "Os Teus Pontos Atuais",
      "current_points": "Pontos Atuais",
      "select_current_points": "Seleciona os teus Pontos Atuais",

      "last_known_rank": "Último Rank Conhecido",
      "last_known_rank_tooltip": "Se foste ranked anteriormente, mas não na season mais recente, seleciona o teu último rank.",

      "no_posts": "Ainda não há publicações!",
      "streaming_tooltip": "Vê o teu Booster jogar ao vivo no Youtube/Twitch num stream privado",
      "voice_tooltip": "Comunicação por voz com o Booster enquanto jogas",

      // MERGE MISSING
      "&_summoner_spells": "& Summoner Spells",
      "choose_champions": "Escolhe os teus Champions",
      "choose_champions_tooltip": "Especifica quais os Champions que queres usar enquanto jogas com o teu booster.",
      "choose_legends": "Escolhe as tuas Legends",
      "choose_legends_tooltip": "Especifica quais as Legends que queres usar enquanto jogas com o teu booster.",
      "choose_legends_tooltip_solo": "Especifica quais as Legends que queres que o booster use, se não forem bloqueados imediatamente.",
      "solo_only": "Apenas Solo",
      "solo_only_tooltip": "Garante que o teu booster não joga com mais ninguém.",
      "streaming_voice": "Streaming + Voz",

      "register_account": "Criar Conta",
      "recover_password": "Recuperar password",
      "mastery_boosting_lol": "Forma garantida de alcançar o nível de Mastery desejado num determinado Champion.",
      "leveling_boosting": "Forma garantida de alcançar o nível de conta desejado através de jogos não ranked.",
      "normal_wins_boosting_lol": "Número desejado de vitórias não ranked.",
      "unrated_wins_valorant": "Número desejado de vitórias não ranked.",
      "games_boosting": "Número desejado de jogos ranked com desempenho garantido do booster.",
      "net_wins_boosting": "Diferencial positivo de vitórias garantido em jogos ranked. Para cada derrota, ganhas uma vitória extra.",
      "mastery_champ_lol": "O teu Champion selecionado para Mastery",
      "map_cs": "O teu mapa selecionado para o boosting competitive.",

      "your_current_lp_amount": "O teu LP atual.",
      "your_current_lp_amount_eq": "O teu LP atual é igual ou superior a",
      "your_current_rr_amount": "A teu RR atual.",
      "your_current_rr_amount_eq": "O teu RR atual é igual ou superior a",
      "your_current_rp_amount": "A teu RP atual.",
      "your_current_marks": "As tuas marks atuais.",

      "tip_tooltip": "Estás a dar uma gorjeta ao teu booster. As gorjetas não são obrigatórias, mas são muito apreciadas. Obrigado!",
      "express_tooltip": "Escolha de alta prioridade e conclusão mais rápida, 24/7.",
      "cashback_tooltip": "de cashback para usar nas tuas próximas compras!",
      "character_tooltip": "Seleciona o teu preferido",
      // "streaming_tooltip": "Assiste aos jogos através da nossa experiência personalizada ao vivo.",

      "valorant_character_tooltip": "Tu selecionaste os teus Agents preferidos.",
      "apex_character_tooltip": "Tu selecionaste os teus Legends preferidos.",
      "lol_character_tooltip": "Tu selecionaste os teus Champions preferidos.",
      "marvel_character_tooltip": "Tu selecionaste os teus Heroes preferidos.",

      "additions": "Adições",

      "playstyle_info_duos": "Deixa o booster saber as tuas preferências pessoais.",

      "choose_roles_tooltip_solo": "Especifica quais os Roles que queres que o booster use ao jogar na tua conta.",
      "choose_champions_tooltip_solo": "Especifica quais os Champions que queres que o booster use, se não forem escolhidos ou banidos.",
      "choose_agents_tooltip_solo": "Especifica quais os Agents que queres que o booster use, se não forem bloqueados imediatamente.",

      // NEW MISSING
      "select_role_champions": "Selecciona os Roles e Champions",
      "select_summoner_spell_keyset": "Tecla dos Summoners",
      "confirm": "Confirmar",

      "select_your_agents": "Seleciona os Agents",

      "request_new_booster_desc": "Informa-nos porque queres um novo booster. Vamos adicionar um assim que possível.",

      "request_reason": "Razão do pedido",

      "assign_favorite_booster": "Adicionar Booster Favorito",
      "assign_favorite_booster_desc": "Os boosters que tiveram uma avaliação positiva dada por ti anteriormente, vão aparecer aqui.",
      "assign_booster": "Adicionar Booster",
      "booster_not_in_game": "Confirma se o teu booster não está a jogar!",

      "request_booster_attention": "Alertar Booster",
      "request_booster_attention_desc": "Notifica o booster adicionado de que estás pronto para continuar o pedido.",
      "you_may_perform": "Podes usar esta ação até",
      "per_day": "vezes por dia",

      "cancel": "Cancelar",

      "tipping_options": "Opções de Gratificação",
      "tipping_options_no_boosters": "Parece que não tens um histórico de boosters neste pedido!",

      "use_credits": "Usar Créditos",
      "custom_tip": "Personalizar",

      "auto_swap_desc": "Ativa a 'Troca Automática', e vamos automaticamente substitur o teu booster por um novo se o atual ficar offline durante",
      "auto_swap_remember": "Lembra-te, esta ação não se aplica a boosters que escolheste directamente da tua lista de favoritos.",
      "auto_swap_current_status": "Estado Actual:",
      "auto_swap_booster_swap": "O booster vai ser trocado em",
      "auto_swap_information_updated": "Informação actualizada a cada 5 minutos.",
      "auto_swap_enable": "Ativar 'Troca Automática'",
      "auto_swap_disable": "Desativar 'Troca Automática'",

      "pages": "Páginas",
      "contact": "Contactos",

      "review_your_booster": "Avalia o Booster",
      "review_your_boosters": "Avalia os Boosters",
      "review_usage": "Usa o sistema de estrelas acima para avaliar o teu booster",
      "review_please_write": "Por favor, escreve a tua avaliação",
      "review_no_booster": "Parece que não tinhas nenhum boosters neste pedido!",

      "looks_like_no_order":"Parece que ainda não tens pedidos aqui!",
      "press_purchase_boost":"Carrega em \"Comprar Boost\" à direita para alcançares o teu ranking desejado agora!",

      //-------------------------------------------

      "report_translation": "Reportar Tradução",
      "open": "Aberto",
      "closed": "Fechado",

      "ticket_add_answer": "Adicionar Resposta",
      "ticket_details": "Detalhes Ticket",
      "ticket_close": "Fechar Ticket",
      "sent_at": "Enviado em",
      "customer": "Cliente",

      "legend": "Legend",
      "badge" : "Badge",

      "country_selection": "Selecionar País",

      "no_boosters_reviewed": "Ainda não tens avaliações dadas por ti a boosters!",

      "no_customizations": "Parece que não tens nenhuma customização neste pedido!",

      // HIDDEN
      "en_flag": "Imagem da bandeira dos EUA",
      "de_flag": "Imagem da bandeira da Alemanha",
      "fr_flag": "Imagem da bandeira da França",
      "es_flag": "Imagem da bandeira da Espanha",
      "pt_flag": "Imagem da bandeira de Portugal",

      // NEW
      "use_your_spendables": "Usa os teus Saldos",
      "store_credits": "Usar Créditos",

      "select_current_marks": "Seleciona Marks Actuais",

      "select_badge": "Selecionar Badge",
      "popular_badges": "Badges Populares",
      "all_badges": "Todos os Badges",

      "select_character_apex": "Selecionar Legend",
      "select_characters_apex": "Selecionar Legends",
      "select": "Selecionar",

      "tip_booster": "Gratificação ao Booster",

      // attention texts
      "new_episode": "Novo Episode",
      "new_split": "Novo split",
      "new_act": "Novo Ato",

      // my-account
      "purchase_boost": "Comprar Boost",
      "create_ticket": "Criar Ticket",
      "active_orders": "Pedidos Ativos",
      "order_history": "Histórico de Pedidos",
      "support": "Tickets",
      "spendables": "Saldos",
      "edit_account": "Minha Conta",
      "logout": "Desconectar",

      "subject": "Assunto",
      "status": "Estado",
      "created_at": "Criado em",

      // new support ticket
      "new_support_ticket": "Novo ticket de suporte",
      "category": "Categoria",
      "select_category": "Selecione uma categoria",
      "bug_report": "Reportar Bug",
      "general_question": "Pergunta Geral",
      "billing_financial": "Facturação",
      "other": "Outro",
      "problem_description": "Descrição",
      "submit_ticket": "Enviar Ticket",

      "service": "Serviço",
      "purchase_date": "Data de compra",
      "details": "Detalhes",

      // spendables
      "amount": "Quantia",
      "currency": "Moeda",
      "available_discounts": "Descontos disponíveis",

      "home_page_title_page": "Blog GGBoost - Principais notícias de gaming e boosting - Página",
      "home_page_title": "Boosting Best-Selling de League of Legends & Boost de Elo Valorant",
      "home_page_desc": "GGBoost é o principal fornecedor de serviços de Elo Boosting para League of Legends e Valorant. Aumente seu rank e melhore sua experiência de jogo hoje!",

      "blog_page_title": "Blog GGBoost - Principais Notícias de Jogos e Boosting",
      "blog_page_title_page": "Blog GGBoost - Principais Notícias de Jogos e Boosting - Página",
      "blog_page_desc": "Mantenha-se atualizado com o blog do GGBoost.com para as últimas novidades em jogos. Descubra artigos, insights e tendências direcionadas para entusiastas de jogos.",

      "boosting_services": "Serviços de Boosting",
      "boosting_services_page_title": "Serviços de Boosting - GGBOOST",

      "blog": "Blog",
      "reviews": "Avaliações",
      "tutorial": "Tutorial",
      "my_account": "A Minha Conta",
      "our_games": "Os Nossos Jogos",
      "best_boost": "A Melhor Experiência de Elo Boosting",
      "get_your": "Obtém o teu",
      "desired_rank": "Rank Desejado",
      "now!": "agora!",
      "check_tutorial": "Ver Tutorial",
      "buy_boost_now": "Comprar Boost Agora",

      "game_selector_title_lol": "Serviços de Elo Boost",
      "game_selector_title_valorant": "Serviços de Boost de Valorant",
      "game_selector_title_tft": "Serviços de Boost de TFT",
      "game_selector_title_wild_rift": "Serviços de Boost de Wild Rift",
      "game_selector_title_cs2": "Serviços de Boost de CS2",
      "game_selector_title_apex": "Serviços de Boost de APEX",

      "best_selling": "Os Serviços de Boosting Mais Vendidos de League of Legends e Valorant",
      "best_selling_desc": "Acreditamos que cada cliente merece ter uma experiência especial e personalizada.",
      "the_safest": "O Mais Seguro",
      "the_safest_desc": "O nosso compromisso para com os nossos clientes é manter sempre o maior qualidade e segurança.",
      "trustworthy": "Confiável",
      "trustworthy_desc": "Com uma classificação de 5 estrelas no Trustpilot, temos o prazer de ser o site de boosting melhor avaliado.",
      "top_results": "Melhores Resultados",
      "top_results_desc": "Lideramos o sector do boosting com uma taxa de vitória média de 87% em todos os serviços.",

      "unique": "O Que Torna o GGBoost Único?",
      "ingame_chat_tracking": "Monitorização de Chat no Jogo e no Lobby",
      "ingame_chat_tracking_desc": "Somos a única empresa no mercado que tem a capacidade de monitorizar tudo o que o booster faz na tua conta para garantir que ela está sempre segura.",
      "login_secure": "O Teu Login Está Sempre Seguro",
      "login_secure_desc": "As tuas informações de login nunca são acessíveis aos boosters. Em vez disso, o nosso programa exclusivo faz o login automaticamente.",
      "advanced_vpn": "VPN Avançado",
      "advanced_vpn_desc": "O nosso VPN Avançado conecta automaticamente o teu booster a uma região próxima de ti, fazendo parecer que estás a jogar em casa.",

      "your_boost_your_rules": "O Teu Boost, As Tuas Regras",
      "fully_customizable": "Totalmente Personalizável",
      "fully_customizable_desc": "Seleciona os teus Champions/Agents preferidos, Roles e a tua ordem usual de Summoner Spells, de forma gratuita. Acelera a conclusão do pedido com a nossa opção prioritária \"Express\".",
      "favorite_boosters": "Boosters Favoritos",
      "favorite_boosters_desc": "Clica no ícone de coração no teu painel de pedidos para adicionar um booster aos teus favoritos. Em pedidos futuros, poderás ver quando eles estiverem disponíveis e atribuí-los facilmente.",
      "live_chat_schedule": "Chat ao Vivo e Agendamento",
      "live_chat_schedule_desc": "Usa o Chat contido no serviço para comunicar com o teu booster em tempo real e agendar as tuas sessões de jogo.",

      "read_more": "Ler Mais",
      "previous_page": "Página Anterior",
      "next_page": "Próxima Página",
      "read_more_about": "Ler mais sobre",

      "ready_to_start": "Pronto para Começar? Compra Agora!",

      "what_our_customers_say": "O Que Os Nossos Clientes Dizem",

      "live_support": "Suporte ao Vivo",
      "got_any_questions": "Tens Alguma Pergunta?",
      "got_any_questions_desc": "Temos uma equipa dedicada de atendimento ao cliente disponível 24/7 para te ajudar com qualquer dúvida.",
      "chat_with_us": "Fala Connosco",

      "about_our_company": "Sobre a Nossa Empresa",

      // service layout
      "last_act_rank": "Rank do Último Act",
      "last_season_rank": "Rank da Última Season",
      "current_rank": "Rank Atual",
      "current_rating": "Rating Atual",
      "current_mastery": "Maestria Atual",
      "current_level": "Nível Atual",
      "desired_badge": "Badge Desejada",
      "desired_amount": "Desejado",
      "desired_rating": "Rating Desejado",
      "desired_mastery": "Maestria Desejada",
      "desired_level": "Nível Desejado",
      "desired_legend": "Lenda Desejada",

      // plays of the week
      "view_more_on": "Ver Mais no",

      // order-flow
      "getting_your_order": "CRIA O TEU SERVIÇO",
      "start_ranking": "Começa a Subir de Rank em 3 Simples Passos",
      "customize_your_service": "Personaliza O Teu Serviço",
      "complete_your_payment": "Completa o Teu Pagamento",
      "select_your_desired_payment_method": "Seleciona o método de pagamento desejado. Suportamos Google Pay, Apple Pay, Visa, Stripe e PaySafeCard, entre outros.",
      "start_ranking_up": "Começa a Subir de Rank",
      "order_ready_to_go": "Agora que o teu pedido está pronto, ser-te-á atribuído um booster imediatamente. É hora de subir nos ranks!",

      // reviews
      "gg_boost_providing": "GGBoost: A Proporcionar Excelência à",
      "years": "Anos",
      "with_over": "Com mais de",
      "customer_reviews": "Avaliações de Clientes",
      "show_more": "Mostrar Mais",

      // buy-authenticate
      "welcome_back": "Bem-Vindo de Volta",
      "please_login_or_register": "Por favor, faça login ou registre uma conta para continuar!",
      "almost_there": "Está quase lá!",
      "please_login_or_register_buy": "Por favor, Registre-se ou Faça Login antes de comprar o seu pedido!",
      "continue_with": "Continuar com",
      "register_with": "Registrar com",
      "or": "ou",
      "forgot_password": "Esqueceu-se da palavra-passe?",
      "have_an_account": "Tem uma conta? Faça login!",
      "duo_boosting_text": "Jogue na sua conta juntamente com um dos nossos boosters.",
      "badges_boosting_text": "Você desbloqueará o Badge",
      "for": "para",

      "solo_boosting_text": "Um dos nossos boosters jogará na sua conta.",
      "division_boosting_text": "Garanta o seu rank desejado.",
      "premier_queue": "Você selecionou a Queue de Ranking Premier para o seu boost.",
      "competitive_queue": "Você selecionou a Queue de Ranking Competitivo para o seu boost.",
      "rating_boost": "Garanta a sua Pontuação CS desejada.",
      "placement_boosting_lol": "Número desejado de jogos de colocação com uma taxa de vitória mínima garantida de 70%.",
      "placement_boosting_valorant": "Número desejado de jogos de colocação com uma taxa de vitória mínima garantida de 80%.",
      "placement_boosting_tft": "Número desejado de jogos de colocação com uma média garantida de 3º lugar ou superior.",
      "placement_boosting_apex": "Número desejado de jogos provisórios com uma média garantida de 2º lugar ou superior.",
      "placement_boosting_cs2_desired": "Número Desejado de",
      "placement_boosting_cs2_queue_premier": "Premier",
      "placement_boosting_cs2_queue_competitive": "Competitivo",
      "placement_boosting_cs2_wins": "Vitórias de Colocação.",

      "free": "Grátis",

      "duo": "Duo",
      "duo_desc": "Tu jogas com o Booster",
      "solo": "Solo",
      "solo_desc": "O Booster Joga na Tua Conta",
      "not_available": "Não Disponível",

      "choose_roles": "Escolhe Roles",
      "and_champs": "e Champions",
      "roles_tooltip": "Especifica quais os Roles que pretendes usar enquanto jogas com o teu booster",

      "choose_agents": "Escolhe os teus Agents",
      "agents_tooltip": "Especifica quais os Agentes que pretendes usar enquanto jogas com o teu booster",

      "choose_heroes": "Escolhe os teus Heroes",
      "choose_heroes_tooltip": "Especifica quais os Heroes que pretendes usar enquanto jogas com o teu booster",
      "choose_heroes_tooltip_solo": "Especifica quais os Heroes que pretendes que o booster use, se não forem bloqueados imediatamente",

      "offline_vpn": "Modo Offline + VPN Avançado",
      "offline_tooltip": "Aparece offline durante o jogo + VPN com deteção automática de localização",

      "coaching": "Elementos de treino",
      "coaching_tooltip": "Pede conselhos sobre como melhorares o teu desempenho num ambiente de jogo ranked.",

      "express_priority": "Prioridade Express",
      "express_priority_tooltip": "Usa Express para teres prioridade mais alta e maior velocidade de conclusão no teu serviço.",

      "use_your_credits": "Usa os teus creditos",
      "use_your_discounts": "Usa os teus descontos",

      "use_global_discount": "Usar Desconto Global",
      "service_discount": "Usar Desconto Serviço",

      "total_price": "Preço Total",

      "excellent": "Excelente",

      "available_credits": "Créditos Disponíveis",
      "order_price": "Preço",
      "credits_used": "Créditos Usados",
      "order_details": "Detalhes do Pedido",
      "use_gg_credits": "Use Créditos GGBoost",

      "select_your_order_details": "Seleciona os detalhes do pedido",

      // flag
      "ready_5_min": "Pronto em 5 minutos",
      "avg_comp_time": "Tempo Médio de Conclusão",

      "hour": "hora",
      "hours": "horas",
      "day": "dia",
      "days": "dias",

      "comp_queue": "Queue Competitiva",
      "queue": "Queue",
      "queue_cs": "Modo",

      "server": "Servidor",
      "platform": "Plataforma",
      "region": "Região",
      "map": "Mapa",

      "estimated_time_left": "Tempo estimado restante",

      "select_rank": "Seleciona o Rank Actual",
      "select_rank_desired": "Seleciona o Rank Desejado",
      "select_queue": "Seleciona a Queue",
      "select_region": "Seleciona a Região",

      "select_current_lp": "Seleciona o LP Actual",
      "select_current_rr": "Seleciona o RR Actual",

      "select_last_season_rank": "Seleciona o Rank da Última Season",

      "current_lp": "LP Actual",
      "current_rp": "RP Actual",
      "current_rr": "RR Actual",
      "current_vp": "VP Actual",

      // trustpilot reviews component
      "trustpilot_reviews_title": "Milhares de Avaliações Verificadas",
      "trustpilot_reviews_subtitle": "Vê Porque Mais De 150.000 Jogadores Confiam Em Nós",

      // apex page
      "coming_soon": "Em breve",

      // payment-success
      "processing_payment": "Processando Pagamento",

      // customer order page
      "pause_order": "Pausar Pedido",
      "unpause_order": "Retomar Pedido",
      "spectate_match": "Observar Jogo",
      "review_order": "Avaliar Pedido",

      // MISSING
      "select_desired_map": "Seleciona o Mapa Desejado",

      "safe_service": "Serviço Seguro",
      "fast_completion": "Conclusão Rápida",
      "money_back": "Devolução Garantida",
      "support_247": "Suporte 24/7",

      "select_current_rp": "Seleciona o RP Actual",
      "select_platform": "Plataforma",

      "account_information": "Informações de Conta",
      "additional_info": "Precisamos de informações adicionais antes de podermos começar com o teu pedido",
      "2fa_notification": "Por favor, assegura-te que tens a autenticação de 2 fatores desativada na tua conta, caso contrário o booster não se vai poder logar.",

      "application": "Aplicação",
      "your_profile_id": "O teu ID de perfil",
      "assign_favorite_booster_label": "Escolhe o teu Booster Favorito",
      "no_favorite_boosters": "Tu não tens boosters favoritos.",
      "first_booster": "Primeiro Booster Disponível",
      "online_boosters": "Boosters online de momento",
      "offline_boosters": "Boosters offline de momento",
      "last_rating": "Último rating",
      "order_notes": "Notas do Pedido",
      "order_notes_placeholder": "Preenche com detalhes que sejam relevantes",
      "customize_order": "Customiza o teu Pedido",
      "playstyle_info": "Seleciona o teu estilo de jogo que o booster deve seguir.",
      "please_fill": "Por favor preenche todos os campos marcados com *",
      "by_clicking": "Ao clicar em 'Guardar & Começar Pedido', tu aceitas o seguinte, ",
      "and": "e",

      "save_start": "Guardar & Começar Pedido",
      "privacy_policy": "Política de Privacidade",
      "terms_use": "Termos de Uso",

      "main_role": "role principal",
      "select_main_role": "Role Principal",
      "secondary_role": "role secundário",
      "select_secondary_role": "Role Secundário",
      "add": "adicionar",

      "select_your_champions": "Seleciona os teus Champions",
      "search": "Procurar",
      "confirm_selection": "Confirmar Selecção",

      "error": "Erro",
      "information_error_msg": "Não conseguimos localizar o teu Riot ID ou então ocorreu um erro durante a processo de pesquisa! Se isto continuar a acontecer, por favor entra em contato com o nosso supporte!",

      "information_mismatch": "Disparidade de Informação",
      "information_mismatch_msg": "O teu rank não se enquadra no pedido que fizeste! Por favor confirma o teu Riot ID!",
      "order_starting_rank": "Rank do Pedido",
      "edit_riot_id": "Editar Riot ID",

      "verify_your_summoner": "Verifica o teu Riot ID",
      "level": "Nivel",
      "confirm_summoner": "Confirmar Riot ID",

      "order_converted": "O teu pedido foi convertido.",
      "order_chargeback": "O teu pedido foi estornado.",
      "order_refunded": "O teu pedido foi devolvido.",
      "order_pending": "O teu pagamento está pendente.",
      "order_rejected": "O teu pagamento foi rejeitado.",
      "order_active_progress": "O teu pedido está em andamento.",
      "order_active_action": "A tua ação é necessária!",
      "order_completed": "O teu pedido está completo!",
      "order_paused": "O teu pedido está em pausa.",

      "order_converted_desc": "O teu pedido foi convertido para creditos com sucesso!",
      "order_chargeback_desc": "Contata o apoio ao cliente para mais informações!",
      "order_refunded_desc": "Efetuamos a devolução, pode demorar até 24 horas!",
      "order_rejected_desc": "O teu pagamento foi recusado pelo prestador de serviços!",
      "order_pending_desc": "O teu pedido vai começar assim que o teu pagamento tiver sido processado!",
      "order_active_progress_desc": "Podes falar com o teu booster no chat do pedido!",
      "order_active_action_desc": "Preenche todos os campos abaixo para começar o teu pedido!",
      "order_completed_desc": "Obrigado por nos teres escolhido! Por favor deixa-nos uma avaliação!",
      "order_paused_desc": "Podes retomar o teu pedido a qualquer momento!",

      "please_edit_credentials": "Por favor edita as tuas credenciais!",
      "please_edit_credentials_desc": "A informação de login que nos forneceste está errada.",
      "account_name": "Nome da Conta",
      "account_password": "Password da Conta",
      "hide": "Esconder",
      "show": "Mostrar",
      "save_continue": "Graver & Continuar Pedido",

      "thank_you": "Obrigado por escolheres GGBOOST!",
      "admin_buy_help_msg": "Quem foi o agente que mais te ajudou durante a tua primeira compra?",
      "admin_buy_help_none": "Nenhum, eu comprei sozinho",

      "match_history": "Histórico de Jogos",
      "loading": "a carregar",

      "waiting_for_booster": "À espera de booster",
      "currently_online": "Atualmente online",
      "currently_away": "Atualmente ausente",
      "currently_offline": "Atualmente offline",
      "textbox_placeholder": "Escreve aqui e pressiona Enter",

      "auto_swap": "Troca Automática",
      "auto_swap_tooltip": "Troca automática se o booster estiver offline.",
      "click_more": "Clica para veres mais.",
      "alert_booster": "Alertar Booster",
      "request_new_booster": "Pedir Booster",
      "request_new_booster_tooltip": "O pedido deve estar parado para poderes pedir um novo booster!",

      "order_id": "ID Pedido",
      "order_type": "Tipo Pedido",
      "order_status": "Estado Pedido",

    },
    "es": {
      "auto_swap_set_order_tooltip": "Se activa cuando el booster está offline por más de 90 minutos. ¡No aplica a favoritos!",

      "no_recent_games": "Sin partidas recientes.",

      "your_favorites": "Tus Favoritos",
      "recently_played_with": "Jugado Recientemente Con",
      "games": "juegos",
      "added_on": "añadido el",

      "favorite_booster_add_tooltip": "Agregar a favoritos",
      "favorite_booster_remove_tooltip": "Eliminar de favoritos",
      "favorite_booster_added": "Booster favorito añadido",
      "favorite_booster_removed": "Booster favorito eliminado",
      "favorite_booster_error_customer_not_found": "Cliente no encontrado",
      "favorite_booster_error_already_exists": "El booster ya está en tu lista de favoritos",
      "favorite_booster_error_booster_not_found": "Booster no encontrado",
      "error_server_or_network": "Error de servidor o red",
      "error_unknown": "Error desconocido",

      "starting": "Inicial",
      "current": "Actual",
      "desired": "Deseado",

      "starting_points": "Puntos Iniciales",
      "starting_lp": "LP Inicial",
      "starting_rp": "RP Inicial",
      "starting_rr": "RR Inicial",
      "starting_vp": "VP Inicial",

      "champ_selection_info": "Seleccionar 4 o más campeones es gratis. Se añade una tarifa del {{REPLACE}}% por elegir entre 1 y 3.",
      "current_selection": "Selección Actual",
      "youll_earn": "Ganarás",
      "crypto_payment_info": "Este pago puede tardar unos minutos en procesarse." ,
      "subtotal": "Subtotal",
      "complete_purchase": "Finalizar compra",
      "global_discount": "Descuento global",
      "platform_fee_tooltip": "Financia la seguridad de los fondos y las mejoras continuas del sitio.",
      "platform_fee": "Tarifa de plataforma",
      "transaction_fee_tooltip": "Tarifa que cubre el procesamiento seguro de pagos.",
      "transaction_fee": "Tarifa de transacción",
      "campaign_discount": "Descuento de Campaña",

      "unranked_wins": "Número de victorias no clasificadas que deseas.",

      "select_character_lol" : "Seleccionar Campeone",
      "select_characters_lol" : "Seleccionar Campeones",
      "select_character_wild-rift" : "Seleccionar Campeone",
      "select_characters_wild-rift" : "Seleccionar Campeones",
      "select_character_valorant" : "Seleccionar Agente",
      "select_characters_valorant" : "Seleccionar Agentes",
      "select_character_legends" : "Seleccionar Leyenda",
      "select_characters_legends" : "Seleccionar Leyendas",
      "select_character_marvel": "Seleccionar Heroe",
      "select_characters_marvel": "Seleccionar Heroes",

      "character_label_lol": "Campeones",
      "character_label_wild-rift": "Campeones",
      "character_label_valorant": "Agentes",
      "character_label_apex": "Leyendas",
      "character_label_marvel": "Heroes",

      "game_selector_title_marvel": "Servicios de Boost de Marvel Rivals",
      "your_current_points": "Tus Puntos Actuales",
      "current_points": "Puntos Actuales",
      "select_current_points": "Selecciona los Puntos Actuales",

      "last_known_rank": "Última Clasificación",
      "last_known_rank_tooltip": "Si has estado clasificado anteriormente pero no en la temporada más reciente, selecciona tu última clasificación registrada.",

      "no_posts": "¡No hay publicaciones todavía!",
      "streaming_tooltip": "Mira a tu Booster jugar en directo en Youtube/Twitch en un stream privado",
      "voice_tooltip": "Abre comunicación por voz con el Booster mientras juegas",

      // MERGE MISSING
      "&_summoner_spells": "& Hechizos de Invocador",
      "choose_champions": "Elige tus Campeones",
      "choose_champions_tooltip": "Di qué Campeones quieres usar mientras juegas con tu booster.",
      "choose_legends": "Elige tus Leyendas",
      "choose_legends_tooltip": "Di qué Leyendas quieres usar mientras juegas con tu booster.",
      "choose_legends_tooltip_solo": "Di qué Leyendas que quieres que el booster use, si no están bloqueados al instante.",
      "solo_only": "Solo Solo",
      "solo_only_tooltip": "Asegura que tu booster no juegue con nadie más.",
      "streaming_voice": "Streaming + Voz",

      "register_account": "Crear Cuenta",
      "recover_password": "Recuperar password",

      "mastery_boosting_lol": "La forma garantizada de alcanzar el nivel de maestría que quieras con un campeón.",
      "leveling_boosting": "La forma garantizada de llegar al nivel de cuenta que quieras jugando partidas no clasificadas.",
      "normal_wins_boosting_lol": "Número de victorias no clasificadas que deseas.",
      "unrated_wins_valorant": "Número de victorias no clasificadas que deseas.",
      "games_boosting": "Número de partidas clasificadas con un buen rendimiento garantizado del booster.",
      "net_wins_boosting": "Número garantizado de victorias clasificadas. Por cada derrota, obtienes una victoria extra.",
      "mastery_champ_lol": "El campeón que seleccionaste para el boost de maestría.",
      "map_cs": "El mapa que seleccionaste para el boost competitivo.",

      "your_current_lp_amount": "Tu cantidad actual de PL.",
      "your_current_lp_amount_eq": "Tu cantidad actual de PL es igual o superior a",
      "your_current_rr_amount": "Tu cantidad actual de RR.",
      "your_current_rr_amount_eq": "Tu cantidad actual de RR es igual o superior a",
      "your_current_rp_amount": "Tu cantidad actual de PR.",
      "your_current_marks": "Tus marcas actuales.",

      "tip_tooltip": "Estás dejando una propina a tu booster. Las propinas no son obligatorias, ¡pero son muy apreciadas! ¡Gracias!",
      "express_tooltip": "Prioridad máxima y finalización más rápida, 24/7.",
      "cashback_tooltip": "de cashback para usar en tus próximas compras.",
      "character_tooltip": "Elige tu",
      // "streaming_tooltip": "Mira las partidas en vivo con nuestra experiencia personalizada.",

      "valorant_character_tooltip": "Ha seleccionado sus Agentes preferidos.",
      "apex_character_tooltip": "Ha seleccionado sus Leyendas preferidas.",
      "lol_character_tooltip": "Ha seleccionado sus Campeones preferidos.",
      "marvel_character_tooltip": "Ha seleccionado sus Heroes preferidos.",

      "additions": "Extras",

      "playstyle_info_duos": "Hazle saber al booster tus preferencias personales.",

      "choose_roles_tooltip_solo": "Di qué Roles que quieres que el booster use al jugar en tu cuenta.",
      "choose_champions_tooltip_solo": "Di qué Campeones que quieres que el booster use, si no están elegidos o baneados.",
      "choose_agents_tooltip_solo": "Di qué Agentes que quieres que el booster use, si no están bloqueados al instante.",


      // NEW MISSING
      "select_role_champions": "Selecciona tus roles y campeones",
      "select_summoner_spell_keyset": "Selecciona el conjunto de hechizos de invocador",
      "confirm": "Confirmar",

      "select_your_agents": "Selecciona tus agentes",

      "request_new_booster_desc": "Háganos saber por qué desea un nuevo booster. Le asignaremos uno nuevo en breve.",

      "request_reason": "Razón de la solicitud",

      "assign_favorite_booster": "Asignar booster favorito",
      "assign_favorite_booster_desc": "Los boosters a los que diste reseñas positivas en pedidos anteriores se agregan a tu lista de favoritos.",
      "assign_booster": "Asignar booster",
      "booster_not_in_game": "¡Asegúrate de que tu booster no esté en el juego!",

      "request_booster_attention": "Solicitar atención del booster",
      "request_booster_attention_desc": "Notifica al booster asignado que estás listo para reanudar el pedido.",
      "you_may_perform": "Puedes realizar esta acción hasta",
      "per_day": "veces por día",

      "cancel": "Cancelar",

      "tipping_options": "Opciones de propina",
      "tipping_options_no_boosters": "¡Parece que no tienes historial de boosters para este pedido!",

      "use_credits": "Usar créditos",
      "custom_tip": "Personalizar",

      "auto_swap_desc": "Habilita 'Cambio Automático' y cambiaremos automáticamente a otro booster si tu actual se desconecta por",
      "auto_swap_remember": "Recuerda, esta función no se aplica a los boosters que seleccionaste personalmente de tu lista de favoritos.",
      "auto_swap_current_status": "Estado actual:",
      "auto_swap_booster_swap": "El booster será cambiado en",
      "auto_swap_information_updated": "Información actualizada cada 5 minutos.",
      "auto_swap_enable": "Habilitar 'Cambio Automático'",
      "auto_swap_disable": "Deshabilitar 'Cambio Automático'",

      "pages": "Páginas",
      "contact": "Contacto",

      "review_your_booster": "Revisa tu booster",
      "review_your_boosters": "Revisa tus boosters",
      "review_usage": "Usa el selector de estrellas arriba para calificar a tu booster",
      "review_please_write": "Por favor escribe tu reseña",
      "review_no_booster": "¡Parece que no tuviste ningún booster en este pedido!",

      "looks_like_no_order":"¡Parece que aún no tienes pedidos aquí!",
      "press_purchase_boost":"Presiona \"Comprar Boost\" a tu derecha para conseguir tu rango deseado ahora!",

      //-------------------------------------------

      "report_translation": "Reportar traducción",
      "open": "Abierto",
      "closed": "Cerrado",

      "ticket_add_answer": "Añadir respuesta",
      "ticket_details": "Detalles del ticket",
      "ticket_close": "Cerrar ticket",
      "sent_at": "Enviado a las",
      "customer": "Cliente",

      "legend": "Leyenda",
      "badge" : "Insignia",

      "country_selection": "Seleccionar país",

      "no_boosters_reviewed": "Aún no has dado reseñas a los boosters!",

      "no_customizations": "¡Parece que no tuviste ningúna personalizaciones en este pedido!",

      // HIDDEN
      "en_flag": "Imagen de la bandera de EE.UU.",
      "de_flag": "Imagen de la bandera de Alemania",
      "fr_flag": "Imagen de la bandera de Francia",
      "es_flag": "Imagen de la bandera de España",
      "pt_flag": "Imagen de la bandera de Portugal",

      // NEW
      "use_your_spendables": "Usa tus créditos",
      "store_credits": "Usa Créditos de tienda",
      "select_current_marks": "Selecciona tus Marcas Actuales",

      "select_badge": "Seleccionar un Insignia",
      "popular_badges": "Insignias Populares",
      "all_badges": "Todas las Insignias",

      "select_character_apex": "Selecciona tu Leyenda",
      "select_characters_apex": "Selecciona tus Leyendas",
      "select": "Seleccionar",

      "tip_booster": "Propina al Booster",

      "login": "Iniciar Sesión",
      "username": "Nombre de Usuario",
      "password": "Contraseña",

      // attention texts,
      "new_episode": "Nuevo Episodio",
      "new_split": "Nueva División",
      "new_act": "Nuevo Acto",
      "new_set": "Nuevo Set",

      // my-account,
      "purchase_boost": "Comprar Boost",
      "create_ticket": "Crear entrada",
      "active_orders": "Órdenes activas",
      "order_history": "Historial de pedidos",
      "support": "Ayuda",
      "spendables": "Gastos",
      "edit_account": "Mi Cuenta",
      "logout": "Cerrar sesión",

      "subject": "Asunto",
      "status": "Estado",
      "created_at": "Creado en",

      "service": "Servicio",
      "purchase_date": "Fecha de compra",
      "details": "Detalles",

      // new support ticket,
      "new_support_ticket": "Nueva entrada de soporte",
      "category": "Categoría",
      "select_category": "Selecciona una categoría",
      "bug_report": "Informe de errores",
      "general_question": "Pregunta general",
      "billing_financial": "Facturación",
      "other": "Otros",
      "problem_description": "Descripción",
      "submit_ticket": "Enviar Entrada",

      // spendables,
      "amount": "Importe",
      "currency": "Moneda",
      "available_discounts": "Descuentos disponibles",

      "home_page_title_page": "GGBoost Blog - Noticias de Gaming y Boost - Página",
      "home_page_title": "El Boosting de League of Legends y Elo Boost de Valorant más vendido",
      "home_page_desc": "GGBoost es el proveedor líder de servicios de Elo Boosting para League of Legends y Valorant. ¡Dale un Boost a tu rango y mejora tu experiencia de juego hoy mismo!",

      "blog_page_title": "GGBoost Blog - Las mejores noticias sobre Boost y Gaming",
      "blog_page_title_page": "GGBoost Blog - Las mejores noticias sobre Boost y Gaming - Page",
      "blog_page_desc": "Mantente al día con el blog de GGBoost.com sobre lo último en videojuegos. Descubre artículos y tendencias adaptados a los entusiastas de los videojuegos.",

      "boosting_services": "Servicios de Boosting",
      "boosting_services_page_title": "Servicios de Boosting - GGBOOST",

      "blog": "Blog",
      "reviews": "Reseñas",
      "tutorial": "Tutorial",
      "my_account": "Mi cuenta",
      "our_games": "Nuestros juegos",
      "best_boost": "La mejor experiencia Elo Boosting",
      "get_your": "Consigue tu",
      "desired_rank": "Rango deseado",
      "now!": "ahora",
      "check_tutorial": "Mirar tutorial",
      "buy_boost_now": "Comprar Boost ahora",

      "game_selector_title_lol": "Servicios de Elo boost",
      "game_selector_title_valorant": "Servicios de Boost de Valorant",
      "game_selector_title_tft": "Servicios de Boost TFT",
      "game_selector_title_wild_rift": "Servicios de Boost de Wild Rift",
      "game_selector_title_cs2": "Servicios de Boost de CS2",
      "game_selector_title_apex": "Servicios de Boost de APEX",

      "best_selling": "Servicios de Boosting de League of Legends y Valorant más vendidos",
      "best_selling_desc": "Creemos que cada cliente merece una experiencia especial a su medida.",
      "the_safest": "El más seguro",
      "the_safest_desc": "Nuestro compromiso con nuestros clientes es mantener siempre un alto nivel de servicio y seguridad.",
      "trustworthy": "Confiable",
      "trustworthy_desc": "Con una asombrosa puntuación de 5 estrellas en Trustpilot, nos complace ser el sitio web de boosting mejor valorado del sector.",
      "top_results": "Los mejores resultados",
      "top_results_desc": "Encabezamos el sector del boosting con un porcentaje de victorias del 87% en todos los servicios.",

      "unique": "¿Qué hace que GGBoost sea único?",
      "ingame_chat_tracking": "Seguimiento del chat en el juego y en el lobby",
      "ingame_chat_tracking_desc": "Somos la única empresa en el mercado que tiene la capacidad de supervisar todo lo que hace el Booster en tu cuenta para garantizar que siempre esté segura.",
      "login_secure": "Tu inicio de sesión es seguro en todo momento",
      "login_secure_desc": "Tu información privada de inicio de sesión nunca es accesible para los boosters, en su lugar nuestro programa único los conecta automáticamente.",
      "advanced_vpn": "VPN avanzado",
      "advanced_vpn_desc": "Nuestra VPN avanzada conecta automáticamente tu boosters a una región cercana a ti haciendo que parezca que estás jugando desde casa.",

      "your_boost_your_rules": "Tu Boost Tus reglas",
      "fully_customizable": "Totalmente personalizable",
      "fully_customizable_desc": "Selecciona tus Roles de Campeones/Agentes preferidos y tu pedido habitual de Hechizos de Invocador completamente gratis. Acelera la finalización del pedido con nuestra opción Express Plus.",
      "favorite_boosters": "Boosters favoritos",
      "favorite_boosters_desc": "Haz clic en el ícono de corazón en tu panel de pedidos para añadir un booster a tus favoritos. En futuros pedidos podrás ver cuándo están disponibles y asignarlos fácilmente.",
      "live_chat_schedule": "Chat en directo y horario",
      "live_chat_schedule_desc": "Utiliza el chat de pedidos para comunicarte con tus boosters en tiempo real y programar tus sesiones de juego Duo.",

      "read_more": "Leer más",
      "previous_page": "Página anterior",
      "next_page": "Página siguiente",
      "read_more_about": "Más información",

      "ready_to_start": "¿Listo para empezar? ¡compra ahora!",

      "what_our_customers_say": "Lo que dicen nuestros clientes",

      "live_support": "Ayuda en directo",
      "got_any_questions": "¿Tienes alguna pregunta?",
      "got_any_questions_desc": "Contamos con un equipo especializado de representantes de atención al cliente disponibles 24/7 para ayudarte con cualquier pregunta.",
      "chat_with_us": "Chatea con nosotros",

      "about_our_company": "Sobre nuestra empresa",

      // service layout,
      "last_act_rank": "Rango del último acto",
      "last_season_rank": "Rango de la temporada pasada",
      "current_rank": "Rango actual",
      "current_rating": "Clasificación actual",
      "current_mastery": "Maestría actual",
      "current_level": "Nivel actual",
      "desired_badge": "Insignia deseada",
      "desired_amount": "Importe deseado",
      "desired_rating": "Calificación deseada",
      "desired_mastery": "Maestría deseada",
      "desired_level": "Nivel deseado",
      "desired_legend": "Leyenda deseada",

      // plays of the week,
      "view_more_on": "Ver más en",

      // order-flow:,
      "getting_your_order": "Cómo obtener tu pedido",
      "start_ranking": "Empieza a clasificarte en 3 sencillos pasos",
      "customize_your_service": "Personaliza tu servicio",
      "complete_your_payment": "Completa tu pago",
      "select_your_desired_payment_method": "Selecciona el método de pago que desees. Admitimos Google Pay Apple Pay Visa Stripe y PaySafeCard entre otros.",
      "start_ranking_up": "Empieza a subir en la clasificación",
      "order_ready_to_go": "Ahora que tu pedido está listo, se te asignará un boosters de inmediato. ¡Es hora de ascender!",

      // reviews,
      "gg_boost_providing": "GGBoost: Ofreciendo excelencia por",
      "years": "años",
      "with_over": "con más de",
      "customer_reviews": "reseñas de clientes",
      "show_more": "Mostrar más",

      // buy-authenticate,
      "welcome_back": "Bienvenido de nuevo",
      "please_login_or_register": "Inicia sesión o registra una cuenta para continuar.",
      "almost_there": "¡Ya casi está!",
      "please_login_or_register_buy": "Regístrate o inicia sesión antes de realizar tu pedido.",
      "continue_with": "Continuar con",
      "register_with": "Regístrate con",
      "or": "o",
      "forgot_password": "¿Has olvidado tu contraseña?",
      "have_an_account": "¿Tienes una cuenta? Inicia sesión",
      "duo_boosting_text": "Juega en tu cuenta con uno de nuestros boosters.",
      "badges_boosting_text": "Desbloquearás la Insignia",
      "for": "para",

      "solo_boosting_text": "Uno de nuestros boosters jugará en tu cuenta.",
      "division_boosting_text": "Guarantee your desired rank.",
      "premier_queue": "Has seleccionado Premier Ranking Queue para tu Boost.",
      "competitive_queue": "Has seleccionado la Competitive Ranking Queue para tu Boost.",
      "rating_boost": "Garantiza la clasificación CS deseada.",
      "placement_boosting_lol": "Número deseado de juegos de clasificación con un porcentaje de victorias mínimo garantizado del 70%.",
      "placement_boosting_valorant": "Número deseado de juegos de clasificación con un porcentaje de victorias mínimo garantizado del 80%.",
      "placement_boosting_tft": "Número deseado de partidas de clasificación con una media garantizada de 3er puesto o superior.",
      "placement_boosting_apex": "Número deseado de partidos provisionales con una media garantizada del 2do puesto o superior.",
      "placement_boosting_cs2_desired": "Número deseado de",
      "placement_boosting_cs2_queue_premier": "Premier",
      "placement_boosting_cs2_queue_competitive": "Competitive",
      "placement_boosting_cs2_wins": "Placement Wins.",

      "free": "Gratis",

      "duo": "Duo",
      "duo_desc": "Formas equipo con un booster",
      "solo": "Solo",
      "solo_desc": "Booster juega en tu cuenta",
      "not_available": "No disponible",

      "choose_roles": "Elige tus funciones",
      "and_champs": "& Campeones",
      "roles_tooltip": "Especifica qué Roles deseas utilizar mientras juegas con tu booster.",

      "choose_agents": "Elige a tus agentes",
      "agents_tooltip": "Especifica qué Agentes deseas utilizar mientras juegas con tus booster.",

      "choose_heroes": "Elige a tus Héroes",
      "choose_heroes_tooltip": "Especifica qué Heroes deseas usar mientras juegas con tu booster.",
      "choose_heroes_tooltip_solo": "Especifica qué Heroes deseas que el booster use, si no están bloqueados al instante.",

      "offline_vpn": "Modo sin conexión + VPN avanzada",
      "offline_tooltip": "Aparecer sin conexión mientras juegas + VPN con detección automática de ubicación",

      "coaching": "Elementos de coaching",
      "coaching_tooltip": "Pide consejo sobre cómo mejorar tu rendimiento en un entorno de juego clasificado.",

      "express_priority": "Prioridad Express",
      "express_priority_tooltip": "Cambia a Express para una mayor prioridad de recogida y velocidad de entrega.",

      "use_your_credits": "Utiliza tus créditos",
      "use_your_discounts": "Utiliza tus descuentos",

      "use_global_discount": "Usar Descuento Global",
      "service_discount": "Usar Descuento Servicio",

      "total_price": "Precio total",

      "excellent": "Excelente",

      "available_credits": "Créditos disponibles",
      "order_price": "Precio",
      "credits_used": "Créditos usados",
      "order_details": "Detalles de la orden",
      "use_gg_credits": "Usar créditos GGBoost",

      "select_your_order_details": "Selecciona los detalles de tu orden",

      // flag,
      "ready_5_min": "Listo en 5 minutos",
      "avg_comp_time": "Tiempo medio de finalización",

      "hour": "hora",
      "hours": "horas",
      "day": 'dia',
      "days": "días",

      "comp_queue": "Queue competitiva",
      "queue": "Queue",
      "queue_cs": "Modo",

      "server": "Servidor",
      "platform": "Plataforma",
      "region": "Región",
      "map": "Mapa",

      "estimated_time_left": "Tiempo restante estimado",

      "select_rank": "Selecciona rango",
      "select_rank_desired": "Selecciona rango deseado",
      "select_queue": "Selecciona Queue",
      "select_region": "Selecciona tu región",

      "select_current_lp": "Selecciona tu LP actual",
      "select_current_rr": "Selecciona tu RR actual",

      "select_last_season_rank": "Selecciona el rango de la temporada pasada",

      "current_lp": "LP actual",
      "current_rp": "RP actual",
      "current_rr": "RR actual",
      "current_vp": "VP actual",

      "marks": "Marcas",

      // trustpilot reviews component,
      "trustpilot_reviews_title": "Miles de reseñas verificadas",
      "trustpilot_reviews_subtitle": "Descubre por qué más de 150.000 jugadores confían en nosotros",

      // apex page,
      "coming_soon": "próximamente",

      // payment-success,
      "processing_payment": "Procesando el pago",

      // customer order page,
      "pause_order": "Pausar orden",
      "unpause_order": "Reanudar orden",
      "spectate_match": "Partida de espectador",
      "review_order": "Reseñar orden",

      // MISSING
      "select_desired_map": "Selecciona tu mapa deseado",

      "safe_service": "Servicio Seguro",
      "fast_completion": "Finalización rápida",
      "money_back": "Garantía de devolución del dinero",
      "support_247": "Soporte 24/7",

      "select_current_rp": "Selecciona tu RP actual",
      "select_platform": "Selecciona plataforma",

      "account_information": "Información de tu cuenta",
      "additional_info": "Necesitamos información adicional antes de poder empezar con tu pedido.",
      "2fa_notification": "Por favor, asegúrate de que la Autenticación de Dos Factores esté DESACTIVADA en tu cuenta, de lo contrario, el booster no podrá iniciar sesión.",

      "application": "Solicitud",
      "your_profile_id": "Tu ID de perfil",
      "assign_favorite_booster_label": "Asignar Booster Favorito",
      "no_favorite_boosters": "Aún no tienes boosters favoritos.",
      "first_booster": "Primer Booster Disponible",
      "online_boosters": "Boosters en línea actualmente",
      "offline_boosters": "Boosters fuera de línea actualmente",
      "last_rating": "Última calificación",
      "order_notes": "Notas del Pedido",
      "order_notes_placeholder": "Llena con cualquier detalle relevante",
      "customize_order": "Personaliza tu pedido",
      "playstyle_info": "Selecciona tus preferencias de estilo de juego que el booster debe seguir.",
      "please_fill": "Por favor, completa todos los campos marcados con *",
      "by_clicking": "Al hacer clic en 'Guardar y Empezar Pedido', aceptas los",
      "and": "y",

      "save_start": "Guardar y Empezar Pedido",
      "privacy_policy": "Política de Privacidad",
      "terms_use": "Términos de Uso",

      "main_role": "Rol principal",
      "select_main_role": "Selecciona tu rol principal",
      "secondary_role": "Rol secundario",
      "select_secondary_role": "Selecciona tu rol secundario",
      "add": "agregar",

      "select_your_champions": "Selecciona tus Champions",
      "search": "Buscar",
      "confirm_selection": "Confirmar Selección",

      "error": "Error",
      "information_error_msg": "¡No podemos encontrar tu Riot ID o ha ocurrido un error durante el proceso de búsqueda! Si esto sigue ocurriendo, por favor contacta a nuestro soporte al cliente.",

      "information_mismatch": "Desajuste de Información",
      "information_mismatch_msg": "¡Tu rango no coincide con el pedido que compraste! ¡Por favor, confirma tu Riot ID!",
      "order_starting_rank": "Rango Inicial del Pedido",
      "edit_riot_id": "Editar Riot ID",

      "verify_your_summoner": "Verifica tu Riot ID",
      "level": "Nivel",
      "confirm_summoner": "Confirmar Riot ID",

      "order_converted": "Tu pedido fue convertido.",
      "order_chargeback": "Tu pedido fue reversado.",
      "order_refunded": "Tu pedido fue reembolsado.",
      "order_pending": "Tu pago está en espera.",
      "order_rejected": "Tu pago fue rechazado.",
      "order_active_progress": "Tu pedido está en progreso.",
      "order_active_action": "¡Se requiere tu acción!",
      "order_completed": "¡Tu pedido está completado!",
      "order_paused": "Tu pedido está en pausa.",

      "order_converted_desc": "¡Tu pedido fue convertido a créditos exitosamente!",
      "order_chargeback_desc": "¡Contacta al soporte al cliente para más información!",
      "order_refunded_desc": "Te hemos emitido un reembolso; puede tardar hasta 24 horas en procesarse.",
      "order_rejected_desc": "¡Tu pago ha sido rechazado por tu proveedor!",
      "order_pending_desc": "¡Tu pedido comenzará tan pronto como se procese tu pago!",
      "order_active_progress_desc": "¡Puedes hablar con tu booster en el chat del pedido!",
      "order_active_action_desc": "¡Rellena los campos a continuación para comenzar tu pedido!",
      "order_completed_desc": "¡Gracias por elegirnos! Por favor, déjanos una reseña.",
      "order_paused_desc": "¡Puedes reanudar tu pedido en cualquier momento!",

      "please_edit_credentials": "¡Por favor, edita tus credenciales!",
      "please_edit_credentials_desc": "Estimado cliente, la información de inicio de sesión que proporcionaste es incorrecta.",
      "account_name": "Nombre de cuenta",
      "account_password": "Contraseña de cuenta",
      "hide": "Esconder",
      "show": "Mostrar",
      "save_continue": "Guardar y Continuar Pedido",

      "thank_you": "¡Gracias por elegir GGBOOST!",
      "admin_buy_help_msg": "¿Quién fue el agente que más te ayudó durante la compra de tu primer pedido?",
      "admin_buy_help_none": "Nadie, compré por mi cuenta",

      "match_history": "Historial de Partidas",
      "loading": "cargando",

      "waiting_for_booster": "Esperando al booster",
      "currently_online": "Actualmente en línea",
      "currently_away": "Actualmente ausente",
      "currently_offline": "Actualmente fuera de línea",
      "textbox_placeholder": "Escribe aquí y presiona Enter",

      "auto_swap": "Auto Swap",
      "auto_swap_tooltip": "Cambio automático si el booster está fuera de línea.",
      "click_more": "Haz clic para ver más.",
      "alert_booster": "Alertar al booster",
      "request_new_booster": "Solicitar nuevo booster",
      "request_new_booster_tooltip": "¡El pedido debe estar en pausa para poder solicitar un nuevo booster!",

      "order_id": "ID del Pedido",
      "order_type": "Tipo de Pedido",
      "order_status": "Estado del Pedido",
    }
  };

  getTranslation(lang: string, key: string): string {
    if (!this.translations[lang]) {
      lang = 'en';
    }
    return this.translations[lang][key] ? this.translations[lang][key] : (this.translations['en'][key] ? this.translations['en'][key] : key);
  }
}